<template>
  <b-container fluid>
    <ResourceList
      :can-create="false" resource="order" soft-deletes title="Reviews"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">{{ [item.customer.forename, item.customer.surname].join(' ') }}</p>
            <p class="align-self-end mb-1 text-muted">Order #{{ item.id }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button @click="requestReview(item)" class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'Send the review to the customer.'"><b-icon icon="check" /> Request Review</b-button>
            <b-button @click="dismissReview(item)" class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'Dismiss this review.'"><b-icon icon="x" /> Dismiss</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchMany(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('reviews', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('reviews', ['batchDelete', 'fetchMany', 'sendRequest', 'dismiss']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({
          ...this.$route.query,
          page,
          orderStatus: this.orderStatus
        })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    },
    requestReview(review) {
      this.sendRequest(review).then(response => {
        this.$root.$emit('card-notification', {
          variant: 'success',
          title: `Review Requested`,
          subtitle: `Order #${response.data.id}`,
          text: `Review Request sent successfully.`,
          timeout: 2500
        })
      })
    },
    dismissReview(review) {
      this.dismiss(review).then(response => {
        this.$root.$emit('card-notification', {
          variant: 'success',
          title: `Review Request Dismissed`,
          subtitle: `Order #${response.data.id}`,
          text: `Review Request dismissed successfully.`,
          timeout: 2500
        })
      })
    },
  }
}
</script>

<style>

</style>
