<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="download"><b-spinner small v-if="downloading"></b-spinner><span v-if="!downloading">Generate Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="customers.length">
            <b-row class="mb-3">
                <b-col cols="12" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Customer Sources</h4>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <div class="accordion">
                        <b-card no-body v-for="(customer, customerInd) in customers" :key="`customer${customerInd}`">
                            <b-card-header class="p-2" v-b-toggle="`accordion${customerInd}`">
                                <b-row>
                                    <b-col cols="12" md="9" class="d-flex flex-row flex-fill align-items-center">
                                        <strong class="h-5 mb-0">{{ customer.forename }} {{ customer.surname }}</strong>
                                    </b-col>
                                    <b-col cols="12" md="3" class="d-flex flex-row flex-fill align-items-center justify-content-end">
                                        <b-card class="p-2 d-flex flex-row align-items-center justify-content-center" no-body>
                                            <p class="mb-0"><strong>Total Sources: </strong>{{ customer.customer_sources_count }}</p>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card-header>
                            <b-collapse :id="`accordion${customerInd}`">
                                <b-table-simple>
                                    <b-thead>
                                        <b-tr>
                                            <b-th>Source</b-th>
                                            <b-th class="text-right">Recorded At</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(source, sourceInd) in customer.customer_sources" :key="`source${sourceInd}`">
                                            <b-td>{{ source.source.name }}</b-td>
                                            <b-td class="text-right">{{ moment(source.created_at).format('DD/MM/YYYY HH:MM') }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      downloading: false,

      customers: []
  }),
  computed: {
      valid() {
              return true
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      }
  },
  methods: {

    download() {
      if(!this.downloading && this.valid) {
        this.downloading = true;
        window.axios.post('/reports/customer-sources', {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate,
        }).then(response => {
          this.customers = response.data
          this.downloading = false
        })
      }
    }
    
  }
}
</script>

<style>

</style>
