import pagination from '../../utils/pagination'
import {
  INSTALLATIONS_BATCH_DELETE_REQUEST, INSTALLATIONS_BATCH_DELETE_SUCCESS, INSTALLATIONS_BATCH_DELETE_FAILURE,
  INSTALLATIONS_FETCH_MANY_REQUEST, INSTALLATIONS_FETCH_MANY_SUCCESS, INSTALLATIONS_FETCH_MANY_FAILURE,
  INSTALLATIONS_FETCH_ALL_REQUEST, INSTALLATIONS_FETCH_ALL_SUCCESS, INSTALLATIONS_FETCH_ALL_FAILURE,
  INSTALLATIONS_CALENDAR_REQUEST, INSTALLATIONS_CALENDAR_SUCCESS, INSTALLATIONS_CALENDAR_FAILURE,
  INSTALLATIONS_FETCH_REQUEST, INSTALLATIONS_FETCH_SUCCESS, INSTALLATIONS_FETCH_FAILURE,
  INSTALLATIONS_SAVE_REQUEST, INSTALLATIONS_SAVE_SUCCESS, INSTALLATIONS_SAVE_FAILURE,
  INSTALLATIONS_PROCESS_REQUEST, INSTALLATIONS_PROCESS_SUCCESS, INSTALLATIONS_PROCESS_FAILURE,
  
  EMPTY_INSTALLATIONS,
  CLEAR_ERRORS
} from './types'

export default {
  [INSTALLATIONS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [INSTALLATIONS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [INSTALLATIONS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [INSTALLATIONS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [INSTALLATIONS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [INSTALLATIONS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [INSTALLATIONS_FETCH_ALL_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [INSTALLATIONS_FETCH_ALL_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [INSTALLATIONS_FETCH_ALL_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [INSTALLATIONS_CALENDAR_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [INSTALLATIONS_CALENDAR_REQUEST] (state) {
    state.calendarData = []
    state.loading = true
  },
  [INSTALLATIONS_CALENDAR_SUCCESS] (state, { data }) {
    state.calendarData = data
    state.loading = false
  },
  [INSTALLATIONS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [INSTALLATIONS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [INSTALLATIONS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [INSTALLATIONS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [INSTALLATIONS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [INSTALLATIONS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [INSTALLATIONS_PROCESS_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [INSTALLATIONS_PROCESS_SUCCESS] (state) {
    state.saving = false
  },
  [INSTALLATIONS_PROCESS_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_INSTALLATIONS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
