import {
  REVIEWS_BATCH_DELETE_REQUEST,
  REVIEWS_BATCH_DELETE_SUCCESS,
  REVIEWS_BATCH_DELETE_FAILURE,
  REVIEWS_FETCH_MANY_REQUEST,
  REVIEWS_FETCH_MANY_SUCCESS,
  REVIEWS_FETCH_MANY_FAILURE,
  REVIEWS_FETCH_REQUEST,
  REVIEWS_FETCH_SUCCESS,
  REVIEWS_FETCH_FAILURE,
  REVIEWS_SAVE_REQUEST,
  REVIEWS_SAVE_SUCCESS,
  REVIEWS_SAVE_FAILURE,

  EMPTY_REVIEWS,
  CLEAR_ERRORS
} from './types'

export default {
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(REVIEWS_FETCH_MANY_REQUEST)
    return window.axios.get('/reviews', { params: { query, page } }).then(response => {
      commit(REVIEWS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REVIEWS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  sendRequest ({ commit, dispatch }, review) {
    return window.axios.get(`/reviews/send-request/${review.id}`)
      .then(response => {
        dispatch('fetchMany', {})
        return response
      }).catch(error => {
        commit(REVIEWS_SAVE_FAILURE, { error })
        throw error
      })
  },
  dismiss ({ commit, dispatch }, review) {
    return window.axios.get(`/reviews/dismiss/${review.id}`)
      .then(response => {
        dispatch('fetchMany', {})
        return response
      }).catch(error => {
        commit(REVIEWS_SAVE_FAILURE, { error })
        throw error
      })
  },
  empty({ commit }) {
    commit(EMPTY_REVIEWS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
