<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" @input="user = {id:null}"/>
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" @input="user = {id:null}"/>
                        </b-form-group>
                        <b-form-group label="User Select">
                            <user-search
                                class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                                type="user"
                                v-model="user"
                                :timesheet-search="true"
                                :timesheet-start-date="selectedStartDate"
                                :timesheet-end-date="selectedEndDate"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="computedRowArray.length">
            <b-row class="mb-2">
                <b-col cols="12" md="7" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Installer Working Hours</h4>
                </b-col>
                <b-col cols="12" md="5" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 pl-2 pr-2 pt-2 pb-0"><strong>System Total: </strong> {{ totalSystemDuration | secondsToHours }}</p>
                        <p class="mb-0 p-2"><strong>Clockify Total: </strong> {{ totalClockifyDuration | secondsToHours }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead>
                            <b-tr>
                                <b-th>Date</b-th>
                                <b-th class="text-center">System Duration</b-th>
                                <b-th class="text-right">Clockify Duration</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(row, rowInd) in computedRowArray" :key="rowInd">
                                <b-td>{{ moment(row.date, 'DD-MM-YYYY').format('DD/MM/YYYY') }}</b-td>
                                <b-td class="text-center">{{ row.system | secondsToHours }}</b-td>
                                <b-td class="text-right">{{ row.clockify | secondsToHours }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'
import UserSearch from '../../../components/UserSearch'

export default {
  mixins: [ momentMixin ],
  components: { UserSearch },
  data: () => ({
      user: { id: null },
      selectedStartDate: null,
      selectedEndDate: null,

      updating: false,

      rows: {},
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },

      computedRowArray() {
          var rows = [];
          if(this.rows) {
            Object.keys(this.rows).forEach(rowKey => {
                rows.push({
                    'date': rowKey,
                    'system': this.rows[rowKey].hasOwnProperty('system') ? this.rows[rowKey].system : 0,
                    'clockify': this.rows[rowKey].hasOwnProperty('clockify') ? this.rows[rowKey].clockify : 0,
                })
            })
          }
          return rows;
      },

      totalClockifyDuration() {
          if(this.computedRowArray.length) {
              return this.computedRowArray.reduce((v, row) => { return v += (row.clockify ?? 0) }, 0) ?? 0;
          }
          return 0;
      },

      totalSystemDuration() {
          if(this.computedRowArray.length) {
              return this.computedRowArray.reduce((v, row) => { return v += (row.system ?? 0) }, 0) ?? 0;
          }
          return 0;
      },
  },
  methods: {

    update() {
      if(!this.updating && this.valid) {
        this.updating = true;
        window.axios.post(`reports/working-hours/${this.user.id}`, {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
        }).then(response => {
            this.rows = response.data
            this.updating = false
        })
      }
    }
    
  },
  filters: {
      secondsToHours(seconds) {
        var minutes = 0;
        var hours = 0;
        if(seconds > 60) {
            minutes = seconds / 60; seconds = Math.floor(seconds % 60);
            if(minutes > 60) {
                hours = minutes / 60; minutes = Math.floor(minutes % 60);
            }
        }

        if(!hours) {
            if(!minutes) return '0';
            return `${Math.floor(minutes)} minutes`;
        }else{
            return `${Math.floor(hours)} hours, ${Math.floor(minutes)} minutes`;
        }
      }
  }
}
</script>

<style>

</style>
