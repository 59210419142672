<template>

    <b-row>
        <b-col cols="12">
            <FullCalendar ref="calendar" :options="calendarOptions"/>
        </b-col>
    </b-row>

</template>

<script>
/* eslint-disable */
import FriendlyDate from './FriendlyDate'
import momentMixin from '../mixins/momentMixin'

// Calendar.
import FullCalendar from '@fullcalendar/vue'
import dayGridWeek from '@fullcalendar/daygrid'
import dayGrid from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

export default {
    components: { FriendlyDate, FullCalendar },
    mixins: [momentMixin],
    data () {
        return {
            calendarOptions: {
                locale: 'gb',
                plugins: [
                    dayGridWeek,
                    dayGrid,
                    interactionPlugin
                ],
                headerToolbar: { center: 'dayGridMonth,dayGridWeek,dayGridDay' },
                customButtons: {
                    today: {
                        text: 'today',
                        click: () => {
                            this.getData('today')
                        }
                    },
                    next: {
                        click: () => {
                            this.getData('next')
                        }
                    },
                    prev: {
                        click: () => {
                            this.getData('prev')
                        }
                    }
                },

                eventClick: (info) => { this.$emit('eventClick', info) },
                firstDay: 1,
                allDaySlot: false,
                initialView: 'dayGridWeek',
                events: [],
            }
        }
    },
    mounted() {
        if(this.date == null) { 
            if(localStorage.getItem('sales-full-calendar-date')) {
                this.date = this.moment(localStorage.getItem('sales-full-calendar-date')).toDate()
            }else{
                this.date = this.moment().toDate()
            }
        }
        window.requestAnimationFrame(() => {
            this.$refs.calendar.getApi().gotoDate(this.date)
            this.getData()
        })
    },
    props: {
        date: {
            required: false,
            default: null,
        },
        data: {
            required: true,
            type: Array,
            default: () => ([])
        },
        isDeleting: {
            default: false,
            type: Boolean
        },
        isLoading: {
            default: false,
            type: Boolean
        },
    },
    computed: {
        calendarEvents() {
            return this.data.map(event => ({
                'id': event.id,
                'title': '#' + event.id + ' ' + event.customer.forename + ' ' + event.customer.surname + ' - £'+event.total_price,
                'start': this.moment(event.created_at).toDate(),
                'end': this.moment(event.created_at).add(60, 'minutes').toDate(),
                'extendedProps': {
                    'type': 'order'
                }
            }))
        },
    },
    methods: {
        getData(method) {
            if(method) this.$refs.calendar.getApi()[method]();
            this.$emit('getData', {
                'start_date': this.moment(this.$refs.calendar.getApi().getDate()).subtract(6, 'days').format('DD/MM/YYYY'),
                'end_date': this.moment(this.$refs.calendar.getApi().getDate()).add(13, 'days').format('DD/MM/YYYY')
            })
            localStorage.setItem('sales-full-calendar-date', this.$refs.calendar.getApi().getDate())
        }
    },
    watch: {
        date(date) {
            if(date) this.$refs.calendar.getApi().gotoDate(date)
        },
        data(data) {
            this.calendarOptions.events = this.calendarEvents
        }
    }
}
</script>

<style lang="scss">

</style>