export const FINANCE_PACKAGES_BATCH_DELETE_REQUEST = 'FINANCE_PACKAGES_BATCH_DELETE_REQUEST'
export const FINANCE_PACKAGES_BATCH_DELETE_SUCCESS = 'FINANCE_PACKAGES_BATCH_DELETE_SUCCESS'
export const FINANCE_PACKAGES_BATCH_DELETE_FAILURE = 'FINANCE_PACKAGES_BATCH_DELETE_FAILURE'

export const FINANCE_PACKAGES_FETCH_MANY_REQUEST = 'FINANCE_PACKAGES_FETCH_MANY_REQUEST'
export const FINANCE_PACKAGES_FETCH_MANY_SUCCESS = 'FINANCE_PACKAGES_FETCH_MANY_SUCCESS'
export const FINANCE_PACKAGES_FETCH_MANY_FAILURE = 'FINANCE_PACKAGES_FETCH_MANY_FAILURE'

export const FINANCE_PACKAGES_FETCH_REQUEST = 'FINANCE_PACKAGES_FETCH_REQUEST'
export const FINANCE_PACKAGES_FETCH_SUCCESS = 'FINANCE_PACKAGES_FETCH_SUCCESS'
export const FINANCE_PACKAGES_FETCH_FAILURE = 'FINANCE_PACKAGES_FETCH_FAILURE'

export const FINANCE_PACKAGES_SAVE_REQUEST = 'FINANCE_PACKAGES_SAVE_REQUEST'
export const FINANCE_PACKAGES_SAVE_SUCCESS = 'FINANCE_PACKAGES_SAVE_SUCCESS'
export const FINANCE_PACKAGES_SAVE_FAILURE = 'FINANCE_PACKAGES_SAVE_FAILURE'

export const EMPTY_FINANCE_PACKAGES = 'EMPTY_FINANCE_PACKAGES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
