<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="orders.length">
            <b-row class="mb-2">
                <b-col cols="12" md="9" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Revenue And Profit Report</h4>
                </b-col>
                <b-col cols="12" md="3" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 p-3"><strong>Total Profit:</strong> {{ totalProfit | currencyFormat }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1" v-for="(order, orderInd) in orders" :key="orderInd">
                            <b-card-header header-tag="header" class="p-3" role="tab" v-b-toggle="`accordion-${orderInd}`">
                                <b-row>
                                    <b-col cols="12" md="9" class="d-flex flex-column flex-fill justify-items-center">
                                        <p class="h5 mb-0"><router-link v-b-popover.hover.left="'View Order.'" :to="{name: 'dash.orders.view', params: { id: order.id }}">Order #{{ order.id }}</router-link></p>
                                        <small class="text-muted pt-2"><router-link class="text-muted" v-b-popover.hover.left="'View Customer.'" :to="{name: 'dash.customers.view', params: { id: order.customer_id }}">{{ [order.customer.forename, order.customer.surname].join(' ') }}</router-link></small>
                                    </b-col>
                                    <b-col cols="12" md="3" class="d-flex flex-column justify-items-center align-items-end">
                                        <b-card no-body>
                                            <p class="mb-0 p-3"><strong>Order Profit:</strong> {{ order.profit | currencyFormat }}</p>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card-header>
                            <b-collapse :id="`accordion-${orderInd}`" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-table-simple hover>
                                        <b-thead>
                                            <b-tr>
                                                <b-th>Product</b-th>
                                                <b-th class="text-center">Sale Price</b-th>
                                                <b-th class="text-center">Cost Price</b-th>
                                                <b-th class="text-center">Commission</b-th>
                                                <b-th class="text-center">VAT</b-th>
                                                <b-th class="text-right">Profit</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(item, itemInd) in order.items" :key="itemInd">
                                                <b-td>{{ item.product.name }}</b-td>
                                                <b-td class="text-center">{{ item.total_price | currencyFormat }}</b-td>
                                                <b-td class="text-center">{{ item.cost_price | currencyFormat }}</b-td>
                                                <b-td class="text-center">{{ item.commission | currencyFormat }}</b-td>
                                                <b-td class="text-center">{{ item.vat | currencyFormat }}</b-td>
                                                <b-td class="text-right">{{ item.profit | currencyFormat }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
      this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
      this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      totalProfit: 0,

      orders: [],
      updating: false
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      }
  },
  methods: {

    update() {
      if(!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/revenue-and-profit', {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate
        }).then(response => {
          this.orders = response.data.orders
          this.totalProfit = response.data.totalProfit
          this.updating = false
        })
      }
    }
    
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
