<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="download"><b-spinner small v-if="downloading"></b-spinner><span v-if="!downloading">Generate Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="items.length">
            <b-row class="mb-2">
                <b-col cols="12" md="9" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Marketing Report</h4>
                </b-col>
                <b-col cols="12" md="3" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 p-3"><strong>Total Cost:</strong> £{{ totalAmount }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead>
                            <b-tr>
                                <b-th>ID</b-th>
                                <b-th class="text-center">Platform</b-th>
                                <b-th class="text-center">Date</b-th>
                                <b-th class="text-right">Cost</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(item, itemInd) in items" :key="itemInd">
                                <b-td>{{ item.id }}</b-td>
                                <b-td class="text-center">{{ item.platform }}</b-td>
                                <b-td class="text-center">{{ item.date ? item.date.format('DD/MM/YYYY') : '' }}</b-td>
                                <b-td class="text-right">£{{ item.cost }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      downloading: false,

      items: [
          {
              'id': 1,
              'platform': 'Google',
              'date': null,
              'cost': 100,
          },
          {
              'id': 2,
              'platform': 'Facebook',
              'date': null,
              'cost': 105,
          },
          {
              'id': 3,
              'platform': 'Google',
              'date': null,
              'cost': 100,
          },
      ],
  }),
  mounted() {
      this.items.forEach(item => {
          item.date = this.moment()
      })
  },
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },

      totalAmount() {
          return (this.items.reduce((v, item) => { return v += item.cost }, 0))
      }
  },
  methods: {

    download() {
      if(!this.downloading && this.valid) {
        this.downloading = true;
        window.axios.get(/* Path */).then(response => {
          this.$root.$emit('card-notification', {
            variant: 'success',
            title: `Marketing Report Generated`,
            subtitle: `${ this.moment(this.selectedStartDate).format('DD/MM/YYYY') } - ${ this.moment(this.selectedEndDate).format('DD/MM/YYYY') }`,
          })
          this.downloading = false
        })
      }
    }
    
  }
}
</script>

<style>

</style>
