<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Installation Calendar</h1>
      </b-col>
    </b-row>

    <b-row class="mb-3">
        <b-col cols="12">
            <b-card>
                <b-row class="mb-3">
                    <b-col cols="12">
                        <h4>Search</h4>
                        <b-form-input v-model="calendarSearch" placeholder="Search..." @input="calendarData"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <h4>Teams</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12 d-flex flex-row flex-wrap">
                        <b-form-checkbox
                            v-for="team in storedTeams"
                            :key="`team${team.id}`"
                            @change="toggleTeamSelect(team.id)"
                            :checked="( selectedTeams.includes(team.id) )"
                            class="m-2">
                            {{ team.name }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
        <b-col cols="12">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <InstallationFullCalendar
                            selectable
                            @select="viewCreateEvent"
                            :data="data"
                            :installationEvents="installationEvents"
                            @getData="calendarData"
                            @eventClick="eventClicked"
                            @dayClick="dayClicked"
                        ></InstallationFullCalendar>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    
    <b-row>
        <b-col>
            <b-modal id="installation-event-modal" title="Create Event" centered hide-footer>
                <b-row>
                    <b-col cols="12">
                        <b-form @submit.prevent="doCreateEvent">
                            <b-form-group label="Name">
                                <b-form-input v-model="newEvent.name"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Description">
                                <b-form-textarea v-model="newEvent.description"></b-form-textarea>
                            </b-form-group>
                            <b-form-group label="Colour">
                                <b-form-select v-model="newEvent.colour">
                                    <b-form-select-option :value="null">Please select a colour</b-form-select-option>
                                    <b-form-select-option value="#B32727">Dark Red</b-form-select-option>
                                    <b-form-select-option value="#498C4B">Dark Green</b-form-select-option>
                                    <b-form-select-option value="#2E3145">Dark Blue</b-form-select-option>
                                    <b-form-select-option value="#4BB5A2">Turquoise</b-form-select-option>
                                    <b-form-select-option value="#AE48B0">Dark Purple</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="Team">
                                <team-search :error-feed="errors.errors" :error-key="'team_id'" :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated" v-model="newEvent.team" />
                            </b-form-group>
                            <b-form-group label="Start Date">
                                <b-form-datepicker v-model="newEvent.startDate"></b-form-datepicker>
                            </b-form-group>
                            <b-form-group label="Start Time">
                                <b-form-input v-model="newEvent.startTime" placeholder="HH:MM"></b-form-input>
                            </b-form-group>
                            <b-form-group label="End Date">
                                <b-form-datepicker v-model="newEvent.endDate"></b-form-datepicker>
                            </b-form-group>
                            <b-form-group label="End Time">
                                <b-form-input v-model="newEvent.endTime" placeholder="HH:MM"></b-form-input>
                            </b-form-group>
                            <hr>
                            <b-button type="submit" block variant="secondary">Save</b-button>
                            <b-button type="button" @click="doDeleteEvent" v-if="newEvent.id" block variant="danger">Delete</b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-modal>
        </b-col>
    </b-row>

    <b-modal id="reorder-modal" centered size="lg" title="Reorder Modal" hide-footer v-model="reorderModal.modal">
      <template #default>
        <b-container v-if="reorderModal.modal">
          <b-row>
            <b-col cols="12">
                <b-list-group>
                    <draggable :emptyInsertThreshold="200" v-model="reorderModal.list" v-bind="dragOptions" @change="onReorderDrag($event)" @start="reorderModal.dragging = true" @end="reorderModal.dragging = false">
                        <transition-group>
                            <b-list-group-item v-for="event in reorderModal.list" :key="`reorder-event-${event.id}`">
                                <p class="mb-0">#{{ event.id }} {{ event.order.customer.forename }} {{ event.order.customer.surname }}</p>
                                <small class="text-muted">{{ fullAddress(event.order.shipping_address, ' | ') }}</small>
                            </b-list-group-item>
                        </transition-group>
                    </draggable>
                </b-list-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <right-panel-overlay v-model="showEventPanel">
        <b-row v-if="eventSelected">
            <b-col class="mt-5">
                <div class="d-flex flex-column align-items-center">
                    <h4 class="text-primary font-weight-bold">{{ (eventSelected.team_id ? (eventSelected.team.initials ? eventSelected.team.initials + ' ' : '' ) : '') + eventSelected.order.customer.forename + ' ' + eventSelected.order.customer.surname }}</h4>
                    <small class="text-muted">{{ moment(eventSelected.install_at).format('DD/MM/YYYY') }}</small>
                    <small class="text-muted" v-if="eventSelected.order.customer.mobile">{{ eventSelected.order.customer.mobile }}</small>
                    <small class="text-muted" v-if="eventSelected.order.customer.second_mobile">{{ eventSelected.order.customer.second_mobile }}</small>
                    <small class="text-muted" v-if="eventSelected.order.customer.landline">{{ eventSelected.order.customer.landline }}</small>
                    <small class="text-muted">{{ eventSelected.order.customer.email }}</small>
                    <b-badge :variant="eventSelected.order.paid ? 'success' : 'danger'" class="mt-2">
                        {{ eventSelected.order.paid ? 'Paid' : 'Unpaid' }}
                    </b-badge>
                    <b-button size="sm" v-b-popover.hover.bottom="'Process this installation'" class="m-1 icon-button d-flex align-items-center justify-content-center" :to="{name: 'dash.installations.process', params: { id: eventSelected.id }}">
                        Process Installation
                    </b-button>
                </div>
                <div class="mx-4">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center mt-2">
                    <h6 class="text-primary">{{ eventSelected.product.name }}</h6>
                    <div class="d-flex flex-row mt-2" v-if="eventSelected.electrics_required || eventSelected.crane_required">
                        <b-badge v-if="eventSelected.electrics_required" class="mx-1" variant="danger">Electrics Required</b-badge>
                        <b-badge v-if="eventSelected.crane_required" class="mx-1" variant="danger">Crane Required</b-badge>
                    </div>
                </div>
                <div v-if="eventSelected.crane_required && eventSelected.crane_time" class="d-flex align-items-center justify-content-center mt-2">
                    <small class="text-muted">Crane Time: {{ eventSelected.crane_time }}</small>
                </div>
                <div class="mx-4">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <h6 class="text-primary mb-1">Shipping Address</h6>
                    <small class="text-muted" v-if="eventSelected.order.shipping_address.address_line_1">{{ eventSelected.order.shipping_address.address_line_1 }}</small>
                    <small class="text-muted" v-if="eventSelected.order.shipping_address.address_line_2">{{ eventSelected.order.shipping_address.address_line_2 }}</small>
                    <small class="text-muted" v-if="eventSelected.order.shipping_address.city">{{ eventSelected.order.shipping_address.city }}</small>
                    <small class="text-muted" v-if="eventSelected.order.shipping_address.county">{{ eventSelected.order.shipping_address.county }}</small>
                    <small class="text-muted" v-if="eventSelected.order.shipping_address.postcode">{{ eventSelected.order.shipping_address.postcode }}</small>
                </div>
                <div class="mx-4" v-if="eventSelected.order.additional_notes">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center px-1" v-if="eventSelected.order.additional_notes">
                    <h6 class="text-primary">Order Note</h6>
                    <b-card no-body class="mx-1 p-2 mt-1 w-100">
                        <p class="white-space-pre m-1">{{ eventSelected.order.additional_notes }}</p>
                    </b-card>
                </div>
                <div class="mx-4">
                    <hr>
                </div>
                <div class="d-flex flex-column align-items-center mb-3">
                    <h6 class="text-primary mb-1">Installation Notes</h6>
                    <div v-if="eventSelected.notes.length" class="w-100">
                        <b-card v-for="(note, noteInd) in eventSelected.notes" :key="`note-${noteInd}`" no-body class="mx-1 p-2 mt-1">
                            <p class="white-space-pre m-1">{{ note.note }}</p>
                            <hr class="mt-0 mb-0">
                            <small class="text-muted m-1">Left By: {{ note.user.name }}</small>
                        </b-card>
                    </div>
                    <div v-else class="w-100">
                        <b-card no-body class="mx-1 p-2 mt-3">
                            <p class="text-center mb-0">There are no notes.</p>
                        </b-card>
                    </div>
                </div>
            </b-col>
        </b-row>
    </right-panel-overlay>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import InstallationFullCalendar from '../../components/InstallationFullCalendar'
import TeamSearch from '../../components/TeamSearch'
import RightPanelOverlay from '../../components/RightPanelOverlay'
import draggable from 'vuedraggable'

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
    eventSelected: {
        id: null,
        team_id: null,
        team: {
            initials: null,
        },
        order: {
            customer: {
                forename: null,
                surname: null,
            },
            shipping_address: {
                address_line_1: null,
                address_line_2: null,
                city: null,
                county: null,
                postcode: null,
            },
            additional_notes: null,
        },
        product: {
            name: null,
        },
        install_at: null,
        electrics_required: false,
        crane_required: false,
        crane_time: null,
        notes: []
    },
    showEventPanel: false,

    startDate: null,
    endDate: null,
    selectedTeams: [],
    storedTeams: [],

    calendarSearch: null,

    newEvent: {
        id: null,
        name: null,
        description: null,
        colour: null,
        team_id: null,
        team: { id: null },
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
    },

    reorderModal: {
        modal: false,
        date: null,
        list: [],
        dragging: false
    }
  }),
  components: {
    FriendlyDate,
    ResourceList,
    TeamSearch,
    InstallationFullCalendar,
    draggable,
    RightPanelOverlay,
  },
  computed: {
    ...mapGetters('installations', {
        'data': 'calendarData',
        'isDeleting': 'isDeleting',
        'isLoading': 'isLoading',
        'pagination': 'pagination',
    }),
    ...mapGetters('events', {
        'errors': 'errors',
        'installationEvents': 'data'
    }),
    ...mapGetters('teams', { 'teams': 'data' }),

    dragOptions() {
        return {
            animation: 0,
            group: "description",
            disabled: "ontouchend" in document,
            ghostClass: "ghost"
        };
    },
  },
  created() {
      this.fetchAll().then(response => {
          this.storedTeams = response.data
      })
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvModal, modalId) => {
      if(modalId == 'installation-event-modal') {
        this.newEvent.id = null
        this.newEvent.name = null
        this.newEvent.description = null
        this.newEvent.colour = null
        this.newEvent.team_id = null
        this.newEvent.team = { id: null }
        this.newEvent.startDate = null
        this.newEvent.endDate = null
        this.newEvent.startTime = null
        this.newEvent.endTime = null
      }
      if(modalId == 'reorder-modal') {
        this.reorderModal = {
            modal: false,
            date: null,
            list: [],
            dragging: false
        }
      }
    })
  },
  methods: {

    ...mapActions('installations', ['fetchManyCalendar']),
    ...mapActions('teams', ['fetchAll']),
    ...mapActions('events', ['createInstallEvent', 'deleteInstallEvent', 'fetchManyInstallationEvents']),
    
    teamUpdated() {
      this.newEvent.team_id = this.newEvent.team.id;
    },

    fullAddress(address, delimiter) {
      var returnAddress = ''
      if(address.address_line_1) returnAddress += `${address.address_line_1}${delimiter}`
      if(address.address_line_2) returnAddress += `${address.address_line_2}${delimiter}`
      if(address.city) returnAddress += `${address.city}${delimiter}`
      if(address.county) returnAddress += `${address.county}${delimiter}`
      if(address.postcode) returnAddress += `${address.postcode}`
      return returnAddress
    },

    viewCreateEvent(payload) {
        this.newEvent.startDate = payload.start
        this.newEvent.endDate = payload.end
        this.newEvent.startTime = this.moment(payload.start).format('HH:mm')
        this.newEvent.endTime = this.moment(payload.end).format('HH:mm')
        this.$bvModal.show('installation-event-modal')
    },
    
    eventClicked(payload) {

        payload = JSON.parse(JSON.stringify(payload))

        console.log({payload})

        switch(payload.event.extendedProps.type) {
            case 'installation':
                this.eventSelected = payload.event.extendedProps.installation
                this.showEventPanel = true
                // this.$router.push()
            break;
            case 'event':
                payload = JSON.parse(JSON.stringify(payload))
                this.newEvent = payload.event.extendedProps.event
                this.newEvent.startDate = this.moment(payload.event.extendedProps.event.start, 'YYYY-MM-DD HH:mm:ss').toDate()
                this.newEvent.endDate = this.moment(payload.event.extendedProps.event.end, 'YYYY-MM-DD HH:mm:ss').toDate()
                this.newEvent.startTime = this.moment(payload.event.extendedProps.event.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                this.newEvent.endTime = this.moment(payload.event.extendedProps.event.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                this.$bvModal.show('installation-event-modal')
            break;
        }
    },

    dayClicked(date) {
        this.reorderModal.date = date
        this.reorderModal.list = this.data.filter( install => {
            return ( this.moment(this.reorderModal.date).isSame(this.moment(install.install_at), 'day'))
        }).sort((a,b) => {
            return Number( a.order_by ) - Number( b.order_by )
        })
        if(this.reorderModal.list.length) {
            this.reorderModal.modal = true
        }else{
            this.reorderModal.list = [];
            this.reorderModal.date = null;
        }
    },

    onReorderDrag($event) {
        if(this.reorderModal.list.length) {
            window.axios.post('/installations/set-order', {
                'installations': this.reorderModal.list,
            }).then(response => {
                this.calendarData()
            })
        }
    },

    doCreateEvent() {
        this.createInstallEvent({
            ...this.newEvent,
            start: this.moment(this.newEvent.startDate).format('DD/MM/YYYY') + ` ${this.newEvent.startTime}`,
            end: this.moment(this.newEvent.endDate).format('DD/MM/YYYY') + ` ${this.newEvent.endTime}`,
        }).then(response => {
            this.calendarData().then(response => {
                this.$bvModal.hide('installation-event-modal')
            })
        });
    },

    doDeleteEvent() {
        this.deleteInstallEvent(this.newEvent).then(response => {
            this.calendarData().then(response => {
                this.$bvModal.hide('installation-event-modal')
            })
        });
    },

    toggleTeamSelect(teamId) {
        if(this.selectedTeams.includes(teamId)) {
            this.selectedTeams.splice(this.selectedTeams.findIndex(team => { return (team == teamId) }), 1)
        }else{
            this.selectedTeams.push(teamId)
        }
        this.calendarData()
    },

    async calendarData (payload) {
        if(payload && payload.start_date && payload.end_date) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': this.selectedTeams,
            'query': this.calendarSearch,
        })
        this.fetchManyInstallationEvents({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': this.selectedTeams,
            'query': this.calendarSearch,
        })
    },
  },
    watch: {
        showEventPanel(newVal) {
            if(!newVal) {
                this.eventSelected = {
                    id: null,
                    team_id: null,
                    team: {
                        initials: null,
                    },
                    order: {
                        customer: {
                            forename: null,
                            surname: null,
                        },
                        shipping_address: {
                            address_line_1: null,
                            address_line_2: null,
                            city: null,
                            county: null,
                            postcode: null,
                        },
                        additional_notes: null
                    },
                    product: {
                        name: null,
                    },
                    install_at: null,
                    electrics_required: false,
                    crane_required: false,
                    crane_time: null,
                    notes: [],
                };
            }
        }
    }
}
</script>
