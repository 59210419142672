<template>
  <b-container fluid>
    <ResourceList
      :can-delete="false" :can-create="false" :can-select-many="false" resource="order" soft-deletes title="Unsigned"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :has-buttons="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-row flex-fill">
            <p v-if="!item.cancelled_at" class="h5 flex-fill mb-0 text-primary"><router-link class="text-dark mb-0" :to="{name: 'dash.orders.view', params: { id: item.id }}">#{{ item.id }} {{ [item.customer.forename, item.customer.surname].join(' ') }}</router-link></p>
            <p v-if="item.cancelled_at" class="h5 flex-fill mb-0 text-primary">{{ [item.customer.forename, item.customer.surname].join(' ') }}</p>
            <p class="align-self-end mb-0 text-muted"><FriendlyDate :date="item.created_at" /></p>
          </div>
          <div class="d-flex flex-column">
            <p class="mb-1"><b-badge :variant="item.paid ? 'success' : 'danger'">{{ item.paid ? 'Paid' : 'Unpaid' }}</b-badge></p>
            <small v-if="item.finance_package_id">Finance Package: {{ item.finance_package.name }}</small>
            <small v-else>Cash Purchase</small>
            <div class="d-flex flex-row">
              <small class="text-muted mr-2" v-if="!item.paid">{{ item.remaining_amount | currencyFormat }} Remaining | {{ item.invoices_paid_total | currencyFormat }} Paid</small>
            </div>
          </div>
          <div v-if="installDates(item).length">
            <p class="mb-1 mb-md-1" v-for="date in installDates(item)" :key="`date${date}`">Installation Date: {{ moment(date).format('DD/MM/YYYY') }}.</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'Resend the sign request for this order.'" @click="resendSignRequest(item)"><fa-icon :icon="'paper-plane'"/> Resend Sign Request</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this order.'" :to="{name: 'dash.orders.view', params: { id: item.id }}"><b-icon icon="person" /> View</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchManyUnsigned(this.$route.query)
  },

  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('orders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('orders', ['deleteMany', 'fetchManyUnsigned']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyUnsigned(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyUnsigned({
          ...this.$route.query,
          page,
        })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyUnsigned({ ...this.$route.query, query })
      }
    },
    resendSignRequest(item) {
      window.axios.post(`/orders/unsigned/resend-sign-request/${item.id}`).then(response => {
        this.fetchManyUnsigned(this.$route.query)
      })
    },
    installDates(order) {
      var installDates = []
      order.items.forEach(orderItem => {
        if(orderItem.install_required && orderItem.install_at) installDates.push(orderItem.install_at)
      })
      return installDates
    },
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
