export const SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST = 'SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST'
export const SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS = 'SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS'
export const SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE = 'SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE'

export const SUPPLY_PRODUCTS_FETCH_MANY_REQUEST = 'SUPPLY_PRODUCTS_FETCH_MANY_REQUEST'
export const SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS = 'SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS'
export const SUPPLY_PRODUCTS_FETCH_MANY_FAILURE = 'SUPPLY_PRODUCTS_FETCH_MANY_FAILURE'

export const SUPPLY_PRODUCTS_FETCH_ALL_REQUEST = 'SUPPLY_PRODUCTS_FETCH_ALL_REQUEST'
export const SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS = 'SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS'
export const SUPPLY_PRODUCTS_FETCH_ALL_FAILURE = 'SUPPLY_PRODUCTS_FETCH_ALL_FAILURE'

export const SUPPLY_PRODUCTS_FETCH_REQUEST = 'SUPPLY_PRODUCTS_FETCH_REQUEST'
export const SUPPLY_PRODUCTS_FETCH_SUCCESS = 'SUPPLY_PRODUCTS_FETCH_SUCCESS'
export const SUPPLY_PRODUCTS_FETCH_FAILURE = 'SUPPLY_PRODUCTS_FETCH_FAILURE'

export const SUPPLY_PRODUCTS_SAVE_REQUEST = 'SUPPLY_PRODUCTS_SAVE_REQUEST'
export const SUPPLY_PRODUCTS_SAVE_SUCCESS = 'SUPPLY_PRODUCTS_SAVE_SUCCESS'
export const SUPPLY_PRODUCTS_SAVE_FAILURE = 'SUPPLY_PRODUCTS_SAVE_FAILURE'

export const EMPTY_SUPPLY_PRODUCTS = 'EMPTY_SUPPLY_PRODUCTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'