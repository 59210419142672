<template>
  <b-container fluid>
    
    <b-row class="mb-4">
      <b-col class="mb-2 mb-md-0" cols="12" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Reports</h1>
      </b-col>
      <b-col class="d-md-none mb-2" cols="12">
        <b-form-select v-model="selectedReportType">
          <b-form-select-option :value="null">Please select a report to generate</b-form-select-option>
          <b-form-select-option :value="reportType.component" v-for="(reportType, ind) in reportTypes" :key="ind">{{ reportType.text }}</b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8">
        <b-form-select class="d-none d-md-block" v-model="selectedReportType">
          <b-form-select-option :value="null">Please select a report to generate</b-form-select-option>
          <b-form-select-option :value="reportType.component" v-for="(reportType, ind) in reportTypes" :key="ind">{{ reportType.text }}</b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <component v-if="selectedReportType" :is="selectedReportType"/>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

// Report Components
import DailyPaymentReport from './ReportTypes/DailyPaymentReport';
import PaymentReport from './ReportTypes/PaymentReport';
import RevenueAndProfit from './ReportTypes/RevenueAndProfit';
import CustomerSources from './ReportTypes/CustomerSources';
import CustomerAcquisitionCostEstimate from './ReportTypes/CustomerAcquisitionCostEstimate';
import TimeToSale from './ReportTypes/TimeToSale';
import SalesReport from './ReportTypes/SalesReport';
import CommissionSheet from './ReportTypes/CommissionSheet';
import InstallerWorkingHours from './ReportTypes/InstallerWorkingHours';
import MarketingReport from './ReportTypes/MarketingReport';

export default {
  mixins: [currentUser],
  created () {
    this.fetchMany(this.$route.query)
  },
  data: () => ({
    selectedReportType: null,
    reportTypes: [
      { component: 'daily-payment-report', text: 'Daily Payment Report' },
      { component: 'payment-report', text: 'Payment Report' },
      { component: 'revenue-and-profit', text: 'Revenue and Profit' },
      { component: 'customer-sources', text: 'Customer Sources' },
      { component: 'customer-acquisition-cost-estimate', text: 'Customer Acquisition Cost Estimate' },
      { component: 'time-to-sale', text: 'Time to Sale' },
      { component: 'sales-report', text: 'Sales Report' },
      { component: 'commission-sheet', text: 'Commission Sheet' },
      { component: 'installer-working-hours', text: 'Installer Working Hours' },
      { component: 'marketing-report', text: 'Marketing Report' },
    ],
  }),
  components: {
    FriendlyDate,
    
    // Reports
    DailyPaymentReport,
    PaymentReport,
    RevenueAndProfit,
    CustomerSources,
    CustomerAcquisitionCostEstimate,
    TimeToSale,
    SalesReport,
    CommissionSheet,
    InstallerWorkingHours,
    MarketingReport
  },
  computed: {
    ...mapGetters('orders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('orders', ['batchDelete', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({
          ...this.$route.query,
          page,
          orderStatus: this.orderStatus
        })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    }
  }
}
</script>

<style>

</style>
