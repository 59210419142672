<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4">
            <b-row class="mb-3">
                <b-col cols="12" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Payment Report</h4>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead>
                            <b-tr>
                                <b-th>Payment Method</b-th>
                                <b-th class="text-right">Total Received</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(received, method) in paymentMethods" :key="method">
                                <b-td>{{ method }}</b-td>
                                <b-td class="text-right">£{{ received }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
      this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
      this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,
      
      paymentMethods: [],
      updating: false
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      }
  },
  methods: {

    update() {
      if(!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/payment-report', {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate
        }).then(response => {
          this.paymentMethods = response.data
          this.updating = false
        })
      }
    }
    
  }
}
</script>

<style>

</style>
