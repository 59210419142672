<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="customers.length">
            <b-row class="mb-2">
                <b-col cols="12" md="7" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Time To Sale</h4>
                </b-col>
                <b-col cols="12" md="5" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 p-3"><strong>Avg Time To Sale:</strong> {{ avgTimeToSale }} day{{ avgTimeToSale == 0 || avgTimeToSale > 1 ? 's' : '' }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="mb-0" hover>
                        <b-thead>
                            <b-tr>
                                <b-th>Customer</b-th>
                                <b-th class="text-right">Time To Sale</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(customer, customerInd) in customers" :key="customerInd">
                                <b-td class="d-flex flex-column">
                                    <p class="h5 mb-0"><router-link v-b-popover.hover.left="'View this customer.'" :to="{name: 'dash.customers.view', params: { id: customer.id }}">{{ [customer.forename, customer.surname].join(' ') }}</router-link></p>
                                    <small class="text-muted">{{ customer.email }}</small>
                                </b-td>
                                <b-td class="text-right">{{ customer.initial_time_to_sale }} day{{ customer.initial_time_to_sale == 0 || customer.initial_time_to_sale > 1 ? 's' : '' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
      this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
      this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      avgTimeToSale: 0,

      customers: [],
      updating: false
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      }
  },
  methods: {

    update() {
      if(!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/time-to-sale', {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate
        }).then(response => {
          this.customers = response.data.customers
          this.avgTimeToSale = response.data.avgTimeToSale
          this.updating = false
        })
      }
    }
    
  }
}
</script>

<style>

</style>
