<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="supply-products" :show-delete="showDelete" :wait-for-input="waitForInput" :error-feed="errorFeed" :error-key="errorKey" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
        <p class="font-weight-bold mb-0  w-100-m-1">{{ result.supply.name }}</p>
        <p class="text-muted mb-0">Expected {{ result.expected_date ? moment(result.expected_date).format('DD/MM/YYYY') : moment(result.supply.expected_date).format('DD/MM/YYYY') }}</p>
        <small class="text-muted mb-0">Storage Location {{ result.storage_location_id ? result.storage_location.name : 'Not Set' }}</small>
    </template>

    <template #selected="{ selected }">
      <b-row>
          <b-col cols="12">
            <p class="font-weight-bold mb-0  w-100-m-1">{{ selected.supply.name }}</p>
            <p class="text-muted mb-0">Expected {{ selected.expected_date ? moment(selected.expected_date).format('DD/MM/YYYY') : moment(selected.supply.expected_date).format('DD/MM/YYYY') }}</p>
            <small class="text-muted mb-0">Storage Location {{ selected.storage_location_id ? selected.storage_location.name : 'Not Set' }}</small>
          </b-col>
      </b-row>
    </template>

    <template #selected-form-area>
      <slot name="form-area" />
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'
import momentMixin from '../mixins/momentMixin'

export default {
  components: { ResourceSearch },
  mixins: [momentMixin],
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
    orderId: Number,
    productId: Number,
    usedSupplyProducts: Array,
  },
  computed: {
    extraFetchParams() {

      var params = {};

      params = {...params, order_id: this.orderId };

      params = {...params, product_id: this.productId };

      if(this.usedSupplyProducts.length) {
          params = {...params, used_supply_products: this.usedSupplyProducts.join(',') };
      }
      
      if (this.type) {
        params = { ...params, type: this.type };
      }

      return params;
    }
  }
}
</script>

<style>

</style>