<template>
  <b-container fluid>
    <ResourceList
      :can-create="false" resource="installation" soft-deletes title="Pending Installation Routes" :has-buttons="false"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :can-select-many="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-fill p-3 text-light" :class="installationClass(item)">
          <div class="flex-column flex-fill">
            <p class="h5 mb-0 text-light"><router-link class="text-light" :to="{name: 'dash.installations.process', params: { id: (item.hasOwnProperty('order_item_id') ? item.order_item_id : item.id) }}">{{ item.order.customer.forename }} {{ item.order.customer.surname }}</router-link></p>
            <div class="d-flex flex-column mt-2">
              <small class="text-light ">{{ fullAddress(item.order.shipping_address, ' | ') }}</small>
              <small v-if="item.nearby_installations.length">Installations Scheduled Nearby</small>
            </div>
            <div class="d-flex flex-row mt-2">
              <b-badge pill variant="warning" class="mb-0 mr-3" v-if="item.electrics_required">Electrics Required</b-badge>
              <b-badge pill variant="warning" class="mb-0" v-if="item.crane_required">Crane Required</b-badge>
            </div>
            <div class="d-flex flex-row mt-2">
              <b-button variant="primary" :to="{name: 'dash.installations.process', params: { id: (item.hasOwnProperty('order_item_id') ? item.order_item_id : item.id) }}" size="sm">View</b-button>
              <b-button variant="secondary" class="ml-2" @click="doCalendarDisplayItem(item)" size="sm">Quick Update</b-button>
              <b-button variant="danger" class="ml-2" @click="dismissVideo(item)" size="sm">Dismiss</b-button>
            </div>
          </div>
          <div class="d-flex flex-column">
            <p class="align-self-end mb-0 text-light" v-if="item.team_id">{{ item.team.deleted_at ? `${item.team.name} (Deleted)` : item.team.name }}</p>
            <p class="align-self-end mb-0 text-light" v-else>No Team Selected</p>
            <small class="align-self-end mb-0 mt-1 text-light">{{ installDate(item.install_at) }}</small>
            <small class="align-self-end mb-0 mt-1 text-light" v-if="item.supply_product && item.supply_product.storage_location_id">Storage Location: {{ item.supply_product.storage_location.name }}</small>
            <div class="d-flex align-items-end justify-content-end mt-2">
              <b-button variant="primary-outline" class="text-light no-outline" size="sm" @click="doMapDisplayItem(item)"><fa-icon :icon="'map-marked'"/></b-button>
            </div>
          </div>
        </div>
      </template>

    </ResourceList>

    <b-modal id="address-modal" centered size="lg" title="Map" v-model="displayMapModal" :hide-footer="!mapDisplayItem">
      <template #default>
        <GmapMap
          v-if="mapDisplayItem || mapDisplayItems.length"
          :center="computedMapCentre"
          :zoom="computedMapZoom"
          map-type-id="terrain"
          style="width: 100%; height: 50vh"
        >
          <template v-if="!mapDisplayItem && mapDisplayItems.length">
            <GmapInfoWindow
              :key="`infomapmarker${singleDisplayItemKey}`"
              :options="getInfoWindowOptions(singleDisplayItemKey)"
              :opened="true"
              :position="{ lat:Number(singleDisplayItem.order.shipping_address.latitude), lng: Number(singleDisplayItem.order.shipping_address.longitude) }"
              v-for="(singleDisplayItem, singleDisplayItemKey) in mapDisplayItems"/>
          <GmapMarker
            @click="mapDisplayItem = singleDisplayItem"
            :icon="dynamicMapMarker(singleDisplayItem)"
            :position="{ lat:Number(singleDisplayItem.order.shipping_address.latitude), lng: Number(singleDisplayItem.order.shipping_address.longitude) }"
            v-for="(singleDisplayItem, singleDisplayItemKey) in mapDisplayItems" :key="`mapmarker${singleDisplayItemKey}`"/>
          </template>
          <GmapInfoWindow v-if="mapDisplayItem"
            :options="getInfoWindowOptions(mapDisplayItem)"
            :opened="true"
            :position="{ lat:Number(mapDisplayItem.order.shipping_address.latitude), lng: Number(mapDisplayItem.order.shipping_address.longitude) }"/>
          <GmapMarker v-if="mapDisplayItem"
            :icon="dynamicMapMarker(mapDisplayItem)"
            :position="{ lat:Number(mapDisplayItem.order.shipping_address.latitude), lng: Number(mapDisplayItem.order.shipping_address.longitude) }"
          />
        </GmapMap>
      </template>
      <template #modal-footer>
        <b-container v-if="mapDisplayItem">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="12" class="d-flex flex-column flex-fill align-items-start justify-content-center mb-2">
                  <h2 class="font-weight-bold mt-1 mb-0">{{ mapDisplayItem.order.customer.forename }} {{ mapDisplayItem.order.customer.surname }}</h2>
                  <b-button variant="link" class="p-0 text-secondary"><small>{{ fullAddress(mapDisplayItem.order.shipping_address, ' | ') }}</small></b-button>
                  <h4 class="mb-0 mt-2" v-if="mapDisplayItem.supply_product">{{ mapDisplayItem.supply_product.supply.name }}</h4>
                  <small class="mb-0 mt-1" v-if="mapDisplayItem.supply_product && mapDisplayItem.supply_product.storage_location_id">Storage Location: {{ mapDisplayItem.supply_product.storage_location.name }}</small>
                  <small class="mb-0 mt-1" v-if="mapDisplayItem.supply_product">Expected Date: {{ mapDisplayItem.supply_product.expected_date ? moment(mapDisplayItem.supply_product.expected_date).format('DD/MM/YYYY') : moment(mapDisplayItem.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <b-modal id="calendar-modal" centered size="lg" title="Quick Update" v-model="displayCalendarModal" :hide-footer="!calendarDisplayItem">
      <template #default>
        <b-row>
          <b-col cols="12">
            <b-row v-if="displayCalendarModal">
              <b-col cols="12">
                <b-form-group label="Team">
                  <team-search :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated" v-model="calendarDisplayItem.team" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="displayCalendarModal">
              <b-col cols="12">
                <b-form-group label="Install At">
                  <b-form-datepicker v-model="calendarInstallAt" @input="calendarDisplayItem.install_at = calendarInstallAt"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Calendar">
                  <InstallationFullCalendar
                      :date="calendarInstallAt"
                      :data="calendarData"
                      @getData="getCalendarData"
                      @dateClick="dateClicked"
                  ></InstallationFullCalendar>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-button variant="secondary" @click="performQuickUpdate">Quick Update</b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'
import InstallationFullCalendar from '../../components/InstallationFullCalendar'

// Google Places
import GooglePlaces from '../../components/GooglePlaces'

window.selectMapDisplayItem = function(ind) {
  window.dispatchEvent(new CustomEvent('select-map-display-item', { detail: ind }))
}

export default {
  mixins: [currentUser, momentMixin],
  data () {
    return {
      startDate: null,
      endDate: null,
      filterName: null,
      installationDate: 'All',
      locale: null,
      team: { id : null },
      mapDisplayItems: [],
      mapDisplayItem: null,
      calendarDisplayItem: null,
      calendarInstallAt: null,
    }
  },
  created () {

    window.addEventListener('select-map-display-item', payload => {
      this.mapDisplayItem = this.mapDisplayItems[payload.detail]
    })

    this.fetchManyPendingRoutes(this.$route.query)
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      switch(modalId) {
        case 'address-modal':
          this.mapDisplayItems = []
          this.mapDisplayItem = null
          break;
        case 'calendar-modal':
          this.calendarDisplayItem = null
          break;
      }
    })
  },
  components: {
    FriendlyDate,
    ResourceList,
    GooglePlaces,
    TeamSearch,
    InstallationFullCalendar
  },
  computed: {
    ...mapGetters('installations', ['data', 'calendarData', 'isDeleting', 'isLoading', 'pagination']),
    displayCalendarModal() {
      return Boolean(this.calendarDisplayItem)
    },
    displayMapModal() {
      return (Boolean(this.mapDisplayItem) || Boolean(this.mapDisplayItems.length))
    },
    computedMapCentre() {
      if(!this.mapDisplayItem) return {lat:54, lng:-2}
      return { lat:Number(this.mapDisplayItem.order.shipping_address.latitude), lng: Number(this.mapDisplayItem.order.shipping_address.longitude) }
    },
    computedMapZoom() {
      if(!this.mapDisplayItem) return 7
      return 15
    }
  },
  methods: {
    ...mapActions('installations', ['batchDelete', 'fetchManyPendingRoutes', 'fetchManyCalendar']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyPendingRoutes(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page }
        })
        await this.fetchManyPendingRoutes({ 
          ...this.$route.query,
          page,
          filterName: this.filterName,
          longitude: (this.locale ? this.locale.longitude : null),
          latitude: (this.locale ? this.locale.latitude : null),
          installationDate: this.installationDate,
          team: this.team.id,
        })
      }
    },
    async doPaginate (page) {
        await this.fetchManyPendingRoutes({ 
          ...this.$route.query,
          filterName: this.filterName,
          longitude: (this.locale ? this.locale.longitude : null),
          latitude: (this.locale ? this.locale.latitude : null),
          installationDate: this.installationDate,
          team: this.team.id,
        })
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyPendingRoutes({ ...this.$route.query, query })
      }
    },
    installDate: function(date) {
      if (!date) return 'No Installation Date.';
      return 'Installation Date: ' + this.moment(date).format('DD/MM/YYYY');
    },

    performQuickUpdate() {
      if(this.calendarDisplayItem) {
        window.axios.post(`installations/quick-update/${this.calendarDisplayItem.id}`, {
          'install_at': this.calendarDisplayItem.install_at,
          'team_id': this.calendarDisplayItem.team_id,
        }).then(response => {
          this.doPaginate(this.pagination.page).then(() => {
            this.calendarDisplayItem = null
          })
        })
      }
    },

    dismissVideo(orderItem) {
      window.axios.post(`installations/routes/dismiss/${orderItem.id}`, {
        'route_comments': orderItem.route_comments
      }).then(response => {
        this.doPaginate()
      })
    },

    dateClicked(payload) {
      this.calendarDisplayItem.install_at = this.moment(payload.dateStr).toDate()
      this.calendarInstallAt = this.moment(payload.dateStr).toDate()
    },

    async getCalendarData (payload) {
        if(payload) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        window.dispatchEvent(new Event('resize'))
        await this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate,
            'teams': this.calendarDisplayItem.team_id ? [this.calendarDisplayItem.team_id] : []
        })
    },
    mapDisplayItemAddress(item) {
      if(!item) return ''
      return this.fullAddress(item.order.shipping_address, ', ')
    },

    teamUpdated() {
      this.calendarDisplayItem.team_id = this.calendarDisplayItem.team.id;
      this.getCalendarData()
    },

    fullAddress(address, delimiter) {
      var returnAddress = ''
      if(address.address_line_1) returnAddress += `${address.address_line_1}${delimiter}`
      if(address.address_line_2) returnAddress += `${address.address_line_2}${delimiter}`
      if(address.city) returnAddress += `${address.city}${delimiter}`
      if(address.county) returnAddress += `${address.county}${delimiter}`
      if(address.postcode) returnAddress += `${address.postcode}`
      return returnAddress
    },

    doMapDisplayItems() {
      window.axios.get('/installations', {
        params: {
          query: this.$route.query,
          filterName: this.filterName,
          longitude: (this.locale ? this.locale.longitude : null),
          latitude: (this.locale ? this.locale.latitude : null),
          installationDate: this.installationDate,
          team: this.team.id,
          skip_paginate: true
        }
      }).then(response => {
        this.mapDisplayItems = response.data
      })
    },

    doCalendarDisplayItem(item) {
      if(!item.team_id) item.team = {id:null}
      this.calendarDisplayItem = JSON.parse(JSON.stringify(item))
      this.calendarInstallAt = this.calendarDisplayItem.install_at
    },

    doMapDisplayItem(item) {
      this.mapDisplayItem = item
    },

    getInfoWindowOptions(item) {
      var contentString = ''
      if(this.mapDisplayItem) {
        var contentString = '<span>' + item.order.shipping_address.postcode + '</span>'
      }else{
        var ind = this.mapDisplayItems.findIndex(displayableItem => function() {
          return (item.id == displayableItem.id)
        })

        if(ind > -1) {
          var contentString = '<span onclick="window.selectMapDisplayItem('+ind+')">' + this.mapDisplayItems[ind].order.shipping_address.postcode + '</span>'
        }
      }

      return {
        content: contentString,
        pixelOffset: {
          width: 0,
          height: -35,
        }
      }
    },

    installationClass(item) {
      if(item.install_at && item.team_id) {
        if(item.supply_product && item.supply_product.arrived) {
          return 'bg-success'
        }
        return 'bg-warning'
      }else{
        if(item.supply_product && item.supply_product.arrived) {
          return 'bg-warning'
        }
        return 'bg-danger'
      }
    },

    dynamicMapMarker(item) {
      if(item.install_at && item.team_id) {
        if(item.supply_product && item.supply_product.arrived) {
          return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
        }
        return 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
      }else{
        if(item.supply_product && item.supply_product.arrived) {
          return 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
        }
        return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
      }
    }
  },
}
</script>

<style lang="scss">

  .no-outline {
    outline: none !important;
    box-shadow: none !important;
  }

  #address-modal {
    .modal-content {
      background-color: #F4F4F6 !important;
      border: 3px solid $primary;
    }

    .modal-header {
      h3 {
        color: $primary;
      }
    }

    .modal-body {
      padding: 0!important;
    }

    .modal-header,
    .modal-footer {
      color: $secondary;
      background-color: $primary;
      border-radius: 0;
    }

    .modal-header .close {
      color: #fff;
      text-shadow: none;
      opacity: 1;
    }
  }

  .bg-success:hover {
    background-color: #3c9150!important;
  }

  .bg-warning {
    background-color: #e67e22!important;
  }

  .bg-warning:hover {
    background-color: #e47718!important;
  }

  .bg-danger:hover {
    background-color: #b82c3a!important;
  }

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }

</style>
