export const INSTALLATIONS_BATCH_DELETE_REQUEST = 'INSTALLATIONS_BATCH_DELETE_REQUEST'
export const INSTALLATIONS_BATCH_DELETE_SUCCESS = 'INSTALLATIONS_BATCH_DELETE_SUCCESS'
export const INSTALLATIONS_BATCH_DELETE_FAILURE = 'INSTALLATIONS_BATCH_DELETE_FAILURE'

export const INSTALLATIONS_FETCH_MANY_REQUEST = 'INSTALLATIONS_FETCH_MANY_REQUEST'
export const INSTALLATIONS_FETCH_MANY_SUCCESS = 'INSTALLATIONS_FETCH_MANY_SUCCESS'
export const INSTALLATIONS_FETCH_MANY_FAILURE = 'INSTALLATIONS_FETCH_MANY_FAILURE'

export const INSTALLATIONS_FETCH_ALL_REQUEST = 'INSTALLATIONS_FETCH_ALL_REQUEST'
export const INSTALLATIONS_FETCH_ALL_SUCCESS = 'INSTALLATIONS_FETCH_ALL_SUCCESS'
export const INSTALLATIONS_FETCH_ALL_FAILURE = 'INSTALLATIONS_FETCH_ALL_FAILURE'

export const INSTALLATIONS_CALENDAR_REQUEST = 'INSTALLATIONS_CALENDAR_REQUEST'
export const INSTALLATIONS_CALENDAR_SUCCESS = 'INSTALLATIONS_CALENDAR_SUCCESS'
export const INSTALLATIONS_CALENDAR_FAILURE = 'INSTALLATIONS_CALENDAR_FAILURE'

export const INSTALLATIONS_FETCH_REQUEST = 'INSTALLATIONS_FETCH_REQUEST'
export const INSTALLATIONS_FETCH_SUCCESS = 'INSTALLATIONS_FETCH_SUCCESS'
export const INSTALLATIONS_FETCH_FAILURE = 'INSTALLATIONS_FETCH_FAILURE'

export const INSTALLATIONS_SAVE_REQUEST = 'INSTALLATIONS_SAVE_REQUEST'
export const INSTALLATIONS_SAVE_SUCCESS = 'INSTALLATIONS_SAVE_SUCCESS'
export const INSTALLATIONS_SAVE_FAILURE = 'INSTALLATIONS_SAVE_FAILURE'

export const INSTALLATIONS_PROCESS_REQUEST = 'INSTALLATIONS_PROCESS_REQUEST'
export const INSTALLATIONS_PROCESS_SUCCESS = 'INSTALLATIONS_PROCESS_SUCCESS'
export const INSTALLATIONS_PROCESS_FAILURE = 'INSTALLATIONS_PROCESS_FAILURE'

export const EMPTY_INSTALLATIONS = 'EMPTY_INSTALLATIONS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
