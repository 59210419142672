<template>
  <router-view />
</template>

<script>
import orderModule from '../../store/orders'
import customerModule from '../../store/customers'
import productModule from '../../store/products'
import extraModule from '../../store/extras'
import financePackagesModule from '../../store/finance-packages'
import supplyProductsModule from '../../store/supply-products'
import userModule from '../../store/users'
import teamModule from '../../store/teams'
import postcodeModule from '../../store/postcodes'
import installationModule from '../../store/installations'


export default {
  beforeCreate () {
    this.$store.registerModule('orders', orderModule)
    this.$store.registerModule('customers', customerModule)
    this.$store.registerModule('products', productModule)
    this.$store.registerModule('extras', extraModule)
    this.$store.registerModule('finance-packages', financePackagesModule)
    this.$store.registerModule('supply-products', supplyProductsModule)
    this.$store.registerModule('users', userModule)
    this.$store.registerModule('teams', teamModule)
    this.$store.registerModule('postcodes', postcodeModule)
    this.$store.registerModule('installations', installationModule)
  },
  destroyed () {
    this.$store.unregisterModule('orders')
    this.$store.unregisterModule('customers')
    this.$store.unregisterModule('products')
    this.$store.unregisterModule('extras')
    this.$store.unregisterModule('finance-packages')
    this.$store.unregisterModule('supply-products')
    this.$store.unregisterModule('users')
    this.$store.unregisterModule('teams')
    this.$store.unregisterModule('postcodes')
    this.$store.unregisterModule('installations')
  }
}
</script>
