<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col md="8">
          <h1 class="font-weight-bold text-primary mb-0">Viewing Order #{{ order.id }}</h1>
          <small class="mb-0 ml-1 text-muted">{{ moment(order.created_at).format('DD/MM/YYYY') }}</small>
        </b-col>
        <b-col md="4" class="d-flex" v-if="!isLoading">
          <b-button class="ml-auto btn-v-router-link" v-if="!order.cancelled_at && !order.deleted_at && !order.customer.deleted_at" :to="{ name: 'dash.orders.update', params: { id: order.id }}" variant="secondary">Edit</b-button>
          <b-button v-if="!order.cancelled_at && !order.deleted_at && !order.customer.deleted_at && !order.paid" :variant="!cancelOrderClicked ? 'danger' : 'dark'" class="ml-2" @click="cancelOrder">{{ cancelOrderClicked ? 'Are you sure?' : 'Cancel' }}</b-button>
          <b-button v-if="order.cancelled_at && !order.deleted_at" variant="danger" class="ml-auto">Cancelled</b-button>
          <b-button v-if="order.deleted_at" variant="outline-danger" class="ml-auto">Trashed</b-button>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column">
                      <p class="h3 font-weight-bold mb-0">{{ order.customer.forename }} {{ order.customer.surname }}</p>
                      <small class="mb-0 text-danger" v-if="order.customer.deleted_at"><small>Customer Trashed.</small></small>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="12" md="6">
                    <b-form-group label="Mobile" :invalid-feedback="validationInvalidFeedback(errors.errors, 'mobile')">
                      <b-form-input :disabled="true" :state="validationState(errors.errors, 'mobile')" v-model="order.customer.mobile" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Second Mobile" :invalid-feedback="validationInvalidFeedback(errors.errors, 'second_mobile')">
                      <b-form-input :disabled="true" :state="validationState(errors.errors, 'second_mobile')" v-model="order.customer.second_mobile" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Landline" :invalid-feedback="validationInvalidFeedback(errors.errors, 'landline')">
                      <b-form-input :disabled="true" :state="validationState(errors.errors, 'landline')" v-model="order.customer.landline" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Email Address">
                      <b-form-input :disabled="true" type="email" v-model="order.customer.email" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <p class="h4 font-weight-bold mb-0">Shipping &amp; Installation Address</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div><hr></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.address_line_1')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_1')" v-model="order.shipping_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.address_line_2')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.address_line_2')" v-model="order.shipping_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.city')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.city')" v-model="order.shipping_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.county')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.county')" v-model="order.shipping_address.county" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'shipping_address.postcode')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'shipping_address.postcode')" v-model="order.shipping_address.postcode" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12" md="6" class="mt-3 mt-md-0">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <p class="h4 font-weight-bold mb-0">Billing Address</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div><hr></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.address_line_1')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_1')" v-model="order.billing_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.address_line_2')" v-model="order.billing_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.city')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.city')" v-model="order.billing_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.county')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.county')" v-model="order.billing_address.county" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'billing_address.postcode')">
                      <b-form-input :disabled="true" :state="validationState(errors, 'billing_address.postcode')" v-model="order.billing_address.postcode" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0 text-muted">Sign Request</p>
                    </div>
                    <div class="d-flex flex-row">
                      <b-button v-if="order.sign_request && order.sign_request.signed && order.sign_request.url" :href="order.sign_request.url" target="_blank">View Document</b-button>
                      <b-button v-if="order.sign_request && order.sign_request.signed && !order.sign_request.url" target="_blank">Signed - Document Not Available</b-button>
                      <b-button variant="outline-danger" v-if="!order.sign_request || !order.sign_request.signed">Awaiting Signature</b-button>
                      <b-button class="ml-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'Resend the sign request for this order.'" @click="resendSignRequest()"><fa-icon :icon="'paper-plane'"/> Resend Sign Request</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('order_documents')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['order_documents'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Order Documents</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['order_documents']">
                  <b-col cols="12" class="p-4">
                    <b-row>
                      <b-col cols="12">
                        <b-list-group>

                          <b-list-group-item>
                            <div class="d-flex flex-row">
                              <div class="flex-fill">
                                <h5 class="mb-0">Delivery Satisfaction</h5>
                                <div class="d-flex flex-column" v-if="order.order_documents.delivery_satisfaction">
                                  <small class="my-1 text-muted" v-if="order.order_documents.delivery_satisfaction.last_sent_at">Last Sent At: {{ moment(order.order_documents.delivery_satisfaction.last_sent_at).format('DD/MM/YYYY') }}</small>
                                </div>
                              </div>
                              <div v-if="!order.order_documents.delivery_satisfaction">
                                <b-button @click="orderDocumentModal = true; orderDocumentType = 'delivery_satisfaction'">Send Delivery Satisfaction</b-button>
                              </div>
                              <div v-if="order.order_documents.delivery_satisfaction">
                                <b-button v-if="order.order_documents.delivery_satisfaction && order.order_documents.delivery_satisfaction.signed && order.order_documents.delivery_satisfaction.url" :href="order.order_documents.delivery_satisfaction.url" target="_blank">View Document</b-button>
                                <b-button v-if="order.order_documents.delivery_satisfaction && order.order_documents.delivery_satisfaction.signed && !order.order_documents.delivery_satisfaction.url" target="_blank">Signed - Document Not Available</b-button>
                                <b-button variant="outline-danger" v-if="!order.order_documents.delivery_satisfaction || !order.order_documents.delivery_satisfaction.signed">Awaiting Signature</b-button>
                                <b-button class="ml-1 mb-0" variant="secondary" v-b-popover.hover.bottom="'Resend this document.'" @click="orderDocumentModal = true; orderDocumentType = 'delivery_satisfaction'"><fa-icon :icon="'paper-plane'"/> Resend Order Document</b-button>
                              </div>
                            </div>
                          </b-list-group-item>

                          <b-list-group-item>
                            <div class="d-flex flex-row">
                              <div class="flex-fill">
                                <h5 class="mb-0">Propensio Delivery Note</h5>
                                <div class="d-flex flex-column" v-if="order.order_documents.propensio_delivery_note">
                                  <small class="my-1 text-muted" v-if="order.order_documents.propensio_delivery_note.last_sent_at">Last Sent At: {{ moment(order.order_documents.propensio_delivery_note.last_sent_at).format('DD/MM/YYYY') }}</small>
                                </div>
                              </div>
                              <div v-if="!order.order_documents.propensio_delivery_note">
                                <b-button @click="orderDocumentModal = true; orderDocumentType = 'propensio_delivery_note'">Send Propensio Delivery Note</b-button>
                              </div>
                              <div v-if="order.order_documents.propensio_delivery_note">
                                <b-button v-if="order.order_documents.propensio_delivery_note && order.order_documents.propensio_delivery_note.signed && order.order_documents.propensio_delivery_note.url" :href="order.order_documents.propensio_delivery_note.url" target="_blank">View Document</b-button>
                                <b-button v-if="order.order_documents.propensio_delivery_note && order.order_documents.propensio_delivery_note.signed && !order.order_documents.propensio_delivery_note.url" target="_blank">Signed - Document Not Available</b-button>
                                <b-button variant="outline-danger" v-if="!order.order_documents.propensio_delivery_note || !order.order_documents.propensio_delivery_note.signed">Awaiting Signature</b-button>
                                <b-button class="ml-1 mb-0" variant="secondary" v-b-popover.hover.bottom="'Resend this document.'" @click="orderDocumentModal = true; orderDocumentType = 'propensio_delivery_note'"><fa-icon :icon="'paper-plane'"/> Resend Order Document</b-button>
                              </div>
                            </div>
                          </b-list-group-item>

                        </b-list-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('videos')" :role="computedVideoItems.length ? 'button' : ''">
                    <b-icon v-if="computedVideoItems.length" class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['videos'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill" :class="{
                        'ml-4': computedVideoItems.length,
                        'justify-content-center': !computedVideoItems.length,
                        'text-muted': !computedVideoItems.length
                      }">
                      <p class="h4 font-weight-bold mb-0">Videos</p>
                    </div>
                    <div class="d-flex flex-row">
                      <b-button variant="outline-danger" v-if="!computedVideoItems.length">No Videos Required</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['videos']">
                  <b-col cols="12" v-if="computedVideoItems.length">
                    <b-card no-body class="mt-3" v-for="item in computedVideoItems" :key="`video_item${item.id}`">
                      <b-row>
                        <b-col md="6">
                          <div class="p-3">
                            <p class="h4 font-weight-bold mb-0">{{ item.product.name }}</p>
                            <b-badge variant="danger" class="mr-2" v-if="item.crane_required" pill>Crane Required</b-badge>
                            <b-badge variant="danger" class="mr-2" v-if="item.electrics_required" pill>Electrics Required</b-badge>
                          </div>
                          <div class="px-3 pb-3" v-if="item.route_comments">
                            <p class="font-weight-bold mb-0">Route Comments:</p>
                            <p class="mb-0">{{ item.route_comments }}</p>
                          </div>
                          <div class="px-3 pb-3" v-if="item.video_id">
                              <b-dropdown variant="outline-danger" id="dropdown-1" text="Request New Video">
                                <b-dropdown-item @click="requestNewVideo(item.id, true)">Request New Video</b-dropdown-item>
                                <b-dropdown-item @click="requestNewVideo(item.id, false)">Remove Video without Request</b-dropdown-item>
                              </b-dropdown>
                          </div>
                        </b-col>
                        <b-col md="6" v-if="item.video_id">
                          <b-embed
                            type="video"
                            controls
                            aspect="16by9"
                            allowfullscreen>
                            <source :src="item.video.url">
                          </b-embed>
                        </b-col>
                        <b-col md="6" class="d-flex flex-column justify-content-center align-items-md-end" v-else>
                          <div class="mx-3 mb-2 mb-md-0">
                            <b-button variant="outline-info" :to="{ name: 'submit-video', params: { id: item.id, email: order.customer.email } }" block target="_blank">Upload</b-button>
                            <b-button variant="outline-danger" block class="mt-2" target="_blank">Waiting for submission.</b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <p class="h4 font-weight-bold mb-0 text-muted" v-if="order.finance_package_id">Finance Package</p>
                    <p v-else class="h4 font-weight-bold mb-0 text-muted">Cash Purchase</p>
                  </b-col>
                  <b-col cols="12" class="mt-3" v-if="order.finance_package_id">
                    <b-form-group label="Finance Package">
                      <b-form-input :disabled="true" v-model="order.finance_package.name"/>
                    </b-form-group>
                    <b-form-group label="Loan ID">
                      <b-form-input :disabled="true" v-model="order.loan_id"/>
                    </b-form-group>
                    <b-form-group label="Finance Amount">
                      <b-form-input :disabled="true" v-model="order.finance_amount"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('invoices')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['invoices'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Invoices</p>
                    </div>
                    <div class="d-flex flex-row">
                      <b-button variant="secondary" class="mr-1" size="sm" :to="{name: 'dash.invoices.create', params: { order_id: order.id }}" v-if="!order.cancelled_at && (order.remaining_amount > 0)">Raise Invoice</b-button>
                      <b-button variant="info" class="mr-1" size="sm" @click.prevent v-b-popover.hover.bottom="'The Total Remaining Amount to be paid.'">{{ order.remaining_amount | currencyFormat }}</b-button>
                      <b-button variant="primary" v-if="order.finance_package_id" class="mr-1" size="sm" @click.prevent v-b-popover.hover.bottom="'The Total Remaining Amount to be paid after finance.'">{{ order.remaining_amount_after_finance | currencyFormat }}</b-button>
                      <b-button variant="secondary" class="mr-1" size="sm" @click.prevent v-b-popover.hover.bottom="'The total of unpaid invoices.'">{{ order.invoices_pending_total | currencyFormat }}</b-button>
                      <b-button variant="primary" size="sm" @click.prevent v-b-popover.hover.bottom="'The total of invoices paid.'">{{ order.invoices_paid_total | currencyFormat }}</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['invoices']">
                  <b-col cols="12" v-if="order.invoices.length">
                    <b-row>
                      <b-col cols="12">
                        <hr class="mt-0">
                      </b-col>
                    </b-row>
                    <b-row v-for="(invoice, invoiceInd) in order.invoices" :key="`invoice${invoiceInd}`">
                      <b-col cols="12">
                        <b-card no-body class="p-3 mb-3">
                          <b-row>
                            <b-col cols="12">
                              <b-row>
                                <b-col cols="7" class="d-flex flex-column align-items-start justify-content-center">
                                  <router-link class="h5 mb-0" variant="secondary" :to="{name: 'dash.invoices.update', params: { id: invoice.id }}">Invoice #{{ invoice.id }}</router-link>
                                  <small v-if="invoice.paid_at"></small>
                                  <small class="text-muted">{{ invoice.amount | currencyFormat }}</small>
                                </b-col>
                                <b-col cols="5" class="d-flex flex-column align-items-end justify-content-start">
                                  <p v-if="invoice.paid_at" class="mb-0">Paid @ {{ moment(invoice.paid_at).format('DD/MM/YYYY') }}</p>
                                  <p v-if="!invoice.paid_at" class="mb-0">Pending Payment</p>
                                  <small class="text-muted" v-if="invoice.payment_method">{{ invoice.payment_method }}</small>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="!order.invoices.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no invoices for this order.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('products_appointment')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products_appointment'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Order Products</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end" cols="12" md="3">
                    <b-button block size="sm" variant="primary">Total: £{{ computedOrderTotal }}</b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['products_appointment']">
                  <b-col cols="12" v-if="!order.items.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no products selected.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="order.items.length" class="p-4">
                    <b-card no-body class="p-3 mb-3" v-for="item in order.items" :key="`orderItem${item.id}`">
                      <b-row>
                        <b-col cols="12">
                          <div class="d-flex flex-column">
                            <div class="d-flex flex-row flex-fill">
                              <div class="flex-column flex-fill">
                                <p class="mb-0 flex-fill">{{ item.product.name }}</p>
                                <div class="flex-row">
                                  <b-badge variant="danger" class="mr-2" v-if="item.install_required" v-b-popover.hover.left="item.install_at ? 'Scheduled: ' + moment(item.install_at).format('DD/MM/YYYY') : 'Not Scheduled.'" pill>Installation Required</b-badge>
                                  <b-badge variant="danger" class="mr-2" v-if="item.crane_required" pill>Crane Required</b-badge>
                                  <b-badge variant="danger" class="mr-2" v-if="item.electrics_required" pill>Electrics Required</b-badge>
                                </div>
                                <small class="text-muted" v-if="item.crane_time">Crane Time: {{ item.crane_time }}</small>
                              </div>
                              <div class="flex-column text-right" v-if="item.supply_product">
                                <p class="mb-0 text-muted">Expected {{ item.supply_product.expected_date ? moment(item.supply_product.expected_date).format('DD/MM/YYYY') : moment(item.supply_product.supply.expected_date).format('DD/MM/YYYY') }}</p>
                                <small class="mb-0 text-muted">{{ item.supply_product.supply.name }}</small>
                              </div>
                            </div>
                            <div class="d-flex flex-column mt-3" v-if="item.extras.length">
                              <b-card>
                                <h6 class="mb-3">Extras:</h6>
                                <b-table-simple small striped>
                                  <b-thead>
                                    <b-tr>
                                      <b-th>Name</b-th>
                                      <b-th class="text-right"><span class="mr-2">Amount</span></b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(extra, extraInd) in item.extras" :key="`extra${item.id}-${extraInd}`">
                                      <b-td>{{ extra.name }}</b-td>
                                      <b-td class="text-right"><span class="mr-2">{{ extra.price | currencyFormat }}</span></b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-card>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!isLoading">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" sm="7" clsass="d-flex flex-row align-items-center">
                        <div class="w-100 d-flex flex-row" @click.prevent="toggleCollapse('dispatched_messages')" role="button">
                          <b-icon class="cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['dispatched_messages'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                          <div class="d-flex flex-column flex-fill ml-4">
                            <p class="h4 font-weight-bold mb-0">Dispatched Messages</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['dispatched_messages']">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row :class="order.dispatched_messages.length ? 'mt-4' : ''" v-if="!collapsed['dispatched_messages']">
                  <b-col cols="12" v-if="!order.dispatched_messages.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There have been no dispatched messages to this customer.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="order.dispatched_messages.length">
                    <b-card no-body>
                      <b-row v-for="(message, messageInd) in order.dispatched_messages" :key="messageInd">
                        <b-col cols="12">
                          <b-row v-if="messageInd > 0">
                            <b-col cols="12">
                                <div class="m-3"><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                              <div class="mt-3 ml-3 mb-0 mb-md-3">
                                <h5 v-if="message.subject">{{ message.subject }}</h5>
                                <h5 v-if="!message.subject">{{ message.type }}</h5>
                                <small class="text-small text-muted" v-if="message.read_at">Read At: {{ moment(message.read_at).format('DD/MM/YYYY') }}<br></small>
                                <small class="text-muted" v-if="message.subject">{{ message.type }}</small>
                              </div>
                            </b-col>
                            <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                              <div class="ml-3 mr-3 mt-0 mt-md-3">
                                <small class="text-right"><span class="text-muted">{{ moment(message.created_at).format('DD/MM/YYYY @ HH:MM') }}</span></small>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                                <div class="m-3"><hr></div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                                <div class="m-3" v-html="message.content">
                                </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="order.additional_notes">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12">
                    <div class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold">Additional Notes</p>
                      <p class="mb-0 text-muted white-space-pre">{{ order.additional_notes }}</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <b-modal v-model="orderDocumentModal" size="lg" centered>
      <template #modal-header>
        <div class="d-flex flex-column">
          <h4>Send Order Document</h4>
          <small v-if="orderDocumentType == 'delivery_satisfaction'">Delivery Satisfaction</small>
          <small v-if="orderDocumentType == 'propensio_delivery_note'">Propensio Delivery Note</small>
        </div>
      </template>

      <div v-if="orderDocumentType == 'delivery_satisfaction'">
        <b-form-group label="Loan Reference" :invalid-feedback="validationInvalidFeedback(orderDocumentsErrors, 'loan_reference')">
          <b-form-input v-model="orderDocuments.delivery_satisfaction.loan_reference" :state="validationState(orderDocumentsErrors, 'loan_reference')"/>
        </b-form-group>
        <b-form-group label="Description of Goods" :invalid-feedback="validationInvalidFeedback(orderDocumentsErrors, 'description_of_goods')">
          <b-form-textarea v-model="orderDocuments.delivery_satisfaction.description_of_goods" :state="validationState(orderDocumentsErrors, 'description_of_goods')"/>
        </b-form-group>
      </div>

      <div v-if="orderDocumentType == 'propensio_delivery_note'">
        <b-form-group label="Finance Agreement No" :invalid-feedback="validationInvalidFeedback(orderDocumentsErrors, 'finance_agreement_no')">
          <b-form-input v-model="orderDocuments.propensio_delivery_note.finance_agreement_no" :state="validationState(orderDocumentsErrors, 'finance_agreement_no')"/>
        </b-form-group>
        <b-form-group label="Description of Goods" :invalid-feedback="validationInvalidFeedback(orderDocumentsErrors, 'description_of_goods')">
          <b-form-input v-model="orderDocuments.propensio_delivery_note.description_of_goods" :state="validationState(orderDocumentsErrors, 'description_of_goods')"/>
        </b-form-group>
      </div>

      <template #modal-footer>
        <b-button @click="sendOrderDocument" :disabled="orderDocumentSending">
          <span v-if="!orderDocumentSending">Send Order Document</span>
          <b-spinner v-else/>
        </b-button>
      </template>
    </b-modal>


  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import FriendlyDate from '../../components/FriendlyDate';

export default {
  mixins: [validation,momentMixin],
  components: { FriendlyDate },
  created () {
    var self = this;
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
    this.fetchManyFinancePackages('','').then(function(response) {
      self.financePackages = response.data.data;
    });
  },
  computed: {
    ...mapGetters('orders', ['errors', 'isLoading', 'isSaving', 'single']),

    computedOrderTotal() {
      var orderTotal = 0;
      this.order.items.forEach(function(item) {
        if(!isNaN(item.total_price)) {
          orderTotal += Number(item.total_price);
        }
      });
      return orderTotal;
    },

    computedVideoItems() {
      return this.order.items.filter(item => { return item.install_required })
    }
  },
  data () {
    return {
      collapsed: {
        order_details: true,
        products_appointment: false,
        invoices: true,
        videos: false,
        dispatched_messages: true,
        order_documents: true,
      },
      order: {
        id: null,
        install_at: null,
        deposit_amount: null,
        crane_required: null,
        electrics_required: null,
        billing_address: {},
        shipping_address: {},
        items: [],
        finance_package_id: null,
        customer: null,
      },
      financePackages: [],
      cancelOrderClicked: false,


      orderDocumentModal: false,
      orderDocumentType: null,

      // Fields to send when sending document
      orderDocuments: {

        delivery_satisfaction: {
          loan_reference: null,
          description_of_goods: null,
        },

        propensio_delivery_note: {
          finance_agreement_no: null,
          description_of_goods: null,
        },
        
      },

      orderDocumentsErrors: [],
      orderDocumentSending: false,
    }
  },
  methods: {

    ...mapActions('orders', ['fetch', 'save']),

    ...mapActions('finance-packages', {'fetchManyFinancePackages': 'fetchMany'}),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit () {
      this.save({
        id: this.$route.params.id,
        install_at: this.order.install_at,
      })
    },

    resendSignRequest() {
      window.axios.post(`/orders/unsigned/resend-sign-request/${this.$route.params.id}`).then(response => {
        //
      })
    },

    requestNewVideo(orderItem, doRequest) {
      window.axios.get('/orders/order-items/'+orderItem+'/request-new-video', { params: {
        do_request: doRequest,
      }}).then(response => {
        this.fetch(this.$route.params.id)
      })
    },

    sendOrderDocument() {
      this.orderDocumentSending = true
      window.axios.post(`/orders/order-document/send/${this.$route.params.id}/${this.orderDocumentType}`, {
        'order_document_type': this.orderDocumentType,
        ...this.orderDocuments[this.orderDocumentType]
      })
        .then(response => {
          this.fetch(this.$route.params.id)
            .then(response => {
              this.orderDocumentModal = false
              this.orderDocumentType = null
              this.orderDocumentSending = false
            })
        })
        .catch(err => {
          this.orderDocumentsErrors = err.response.data.errors
          this.orderDocumentSending = false
        })
    },

    cancelOrder() {
      if(this.cancelOrderClicked) {
        axios.post(`/orders/cancel/${this.order.id}`).then(response => {
          if([ 200, 201 ].includes(response.status)) this.$router.push({
            name: 'dash.orders.list',
            params: {
              notifications: [{
                variant: 'success',
                title: `Order #${response.data.id}`,
                text: `The order has been cancelled successfully.`,
                timeout: 2500
              }]
            }
          })
        })
      }else{
        this.cancelOrderClicked = true
        setTimeout(() => {
          this.cancelOrderClicked = false;
        } , 3000)
      }
    }
  },
  watch: {
    single () {
      this.order = this.single
    },
    orderDocumentModal(newVal) {
      if(!newVal) orderDocumentType = null
      this.orderDocuments = {
        delivery_satisfaction: {
          loan_reference: null,
          description_of_goods: null,
        },

        propensio_delivery_note: {
          finance_agreement_no: null,
          description_of_goods: null,
        },
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style lang="scss">

a.btn-v-router-link {
  display: grid;
  place-items: center;
}

</style>
