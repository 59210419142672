<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="download"><b-spinner small v-if="downloading"></b-spinner><span v-if="!downloading">Generate Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="sources.length">
            <b-row class="mb-3">
                <b-col cols="12" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Cost Per Acquisition</h4>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead>
                            <b-tr>
                                <b-th>Source</b-th>
                                <b-th class="text-center">Customers</b-th>
                                <b-th class="text-center">Ad Cost</b-th>
                                <b-th class="text-right">CPA</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(source, sourceInd) in sources" :key="sourceInd">
                                <b-td>{{ source.name }}</b-td>
                                <b-td class="text-center">{{ source.customer_sources_count }}</b-td>
                                <b-td class="text-center">-</b-td>
                                <b-td class="text-right">-</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th>Totals</b-th>
                                <b-th class="text-center">{{ totalCustomerCount }}</b-th>
                                <b-th class="text-center">{{ totalAdCost | currencyFormat }}</b-th>
                                <b-th class="text-right">{{ totalCPA | currencyFormat }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      downloading: false,

      sources: []
  }),
  computed: {
      valid() {
          return true;
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },

      totalAdCost() {
          return 0
      },

      totalCustomerCount() {
          return (this.sources.reduce((v, source) => { return v += Number(source.customer_sources_count) }, 0)) ?? 0
      },

      totalCPA() {
          return (this.totalAdCost / this.totalCustomerCount) ?? 0
      }
  },
  methods: {

    download() {
      if(!this.downloading && this.valid) {
        // this.downloading = true;
        window.axios.post('/reports/cost-per-acquisition', {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate,
        }).then(response => {
          this.sources = response.data
          this.$root.$emit('card-notification', {
            variant: 'success',
            title: `CPA Report Generated`,
            subtitle: (this.selectedStartDate && this.selectedEndDate) ? `${ this.moment(this.selectedStartDate).format('DD/MM/YYYY') } - ${ this.moment(this.selectedEndDate).format('DD/MM/YYYY') }` : null,
            timeout: 2500,
          })
          this.downloading = false
        })
      }
    }
    
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
