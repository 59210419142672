export const REVIEWS_BATCH_DELETE_REQUEST = 'REVIEWS_BATCH_DELETE_REQUEST'
export const REVIEWS_BATCH_DELETE_SUCCESS = 'REVIEWS_BATCH_DELETE_SUCCESS'
export const REVIEWS_BATCH_DELETE_FAILURE = 'REVIEWS_BATCH_DELETE_FAILURE'

export const REVIEWS_FETCH_MANY_REQUEST = 'REVIEWS_FETCH_MANY_REQUEST'
export const REVIEWS_FETCH_MANY_SUCCESS = 'REVIEWS_FETCH_MANY_SUCCESS'
export const REVIEWS_FETCH_MANY_FAILURE = 'REVIEWS_FETCH_MANY_FAILURE'

export const REVIEWS_FETCH_REQUEST = 'REVIEWS_FETCH_REQUEST'
export const REVIEWS_FETCH_SUCCESS = 'REVIEWS_FETCH_SUCCESS'
export const REVIEWS_FETCH_FAILURE = 'REVIEWS_FETCH_FAILURE'

export const REVIEWS_SAVE_REQUEST = 'REVIEWS_SAVE_REQUEST'
export const REVIEWS_SAVE_SUCCESS = 'REVIEWS_SAVE_SUCCESS'
export const REVIEWS_SAVE_FAILURE = 'REVIEWS_SAVE_FAILURE'

export const EMPTY_REVIEWS = 'EMPTY_REVIEWS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
