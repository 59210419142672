<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="User Select">
                            <user-search class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="user" v-model="user" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="Start Date">
                            <b-form-datepicker v-model="selectedStartDate" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="End Date">
                            <b-form-datepicker v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="orders.length">
            <b-row class="mb-2">
                <b-col cols="12" md="9" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Commission Report</h4>
                </b-col>
                <b-col cols="12" md="3" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 p-3"><strong>Total Commission:</strong> {{ computedTotalCommission | currencyFormat }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">


                    <b-card v-for="(order, orderInd) in orders" :key="`order${orderInd}`">
                        <b-row>
                            <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapsed(orderInd)" role="button">
                                <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="!isSelected(orderInd) ? 'chevron-right' : 'chevron-down'"></b-icon>
                                <div class="d-flex flex-column flex-fill ml-4">
                                    <p class="h4 font-weight-bold mb-0">Order #{{ order.id }}</p>
                                    <small>{{ moment(order.created_at).format('DD/MM/YYYY') }}</small>
                                </div>
                            </b-col>
                            <b-col class="d-flex flex-column align-items-end justify-content-center" cols="12" md="3">
                                <b-button block size="sm" variant="primary">Total: {{ order.commission | currencyFormat }}</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3" v-if="isSelected(orderInd)">
                            <b-col cols="12">
                                <b-table-simple class="mb-0">
                                    <b-thead>
                                        <b-tr>
                                            <b-th>Product</b-th>
                                            <b-th class="text-center">Cost Price</b-th>
                                            <b-th class="text-center">Sale Price</b-th>
                                            <b-th class="text-right">Commission</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(item, itemInd) in order.items" :key="`item${itemInd}`">
                                            <b-td>{{ item.product.name }}</b-td>
                                            <b-td class="text-center">{{ item.cost_price | currencyFormat }}</b-td>
                                            <b-td class="text-center">{{ item.total_price | currencyFormat }}</b-td>
                                            <b-td class="text-right">{{ item.commission | currencyFormat }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-card>

                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'
import UserSearch from '../../../components/UserSearch'

export default {
  mixins: [ momentMixin ],
  components: { UserSearch },
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,

      updating: false,

      collapsed: [],

      user: {},

      orders: [],
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },

      computedTotalCommission() {
          return this.orders.reduce(( accumulator, order ) => { return accumulator += parseFloat(order.commission) }, 0)
      }
  },
  methods: {

    toggleCollapsed(ind) {
        if(this.isSelected(ind)) this.collapsed.splice(this.collapsed.findIndex((collapsedInd) => ( collapsedInd == ind ) ), 1)
        else this.collapsed.push(ind)
    },

    isSelected(ind) {
        return this.collapsed.includes(ind)
    },

    update() {
      if(!this.updating && this.valid) {
        this.updating = true;
        window.axios.post(`/reports/commission/${this.user.id}`, {
            'start_date': this.selectedStartDate,
            'end_date': this.selectedEndDate,
        }).then(response => {
          this.orders = response.data
          this.updating = false
        })
      }
    }
    
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
