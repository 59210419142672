<template>
    <input type="text" class="form-control mb-4" @keyup="searchChange" ref="google-places-input" placeholder="Enter a location."> 
</template>

<script>

export default {
  data: () => ({
    locationSelected: false,
    latitude: null,
    longitude: null,
  }),
  mounted() {
        this.googleMaps()
  },
  methods: {

        searchChange() {
            if(this.locationSelected) {
                this.$emit('input', { latitude: null, longitude: null })
                this.locationSelected = false
            }
        },

        async googleMaps() {

            var input = await new Promise(resolve => {
                setInterval(() => {
                    try {
                        resolve(this.$refs['google-places-input'])
                    } catch (error) {}
                }, 100)
            })

            var options = {
                componentRestrictions: {
                    country: 'gb'
                }
            }
            var searchBox = new google.maps.places.Autocomplete(input, options)					
            
            searchBox.addListener('place_changed', () => {
                var place = searchBox.getPlace()
        
                this.latitude = place.geometry.location.lat()
                this.longitude = place.geometry.location.lng()
                
                this.search = place.formatted_address
                
                this.locationSelected = true
                
                this.$emit('input', { latitude: this.latitude, longitude: this.longitude })
                
            })
        }
  }
}
</script>