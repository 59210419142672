<template>
    <div>
        <b-card>
            <b-form>

                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Date">
                            <b-form-datepicker v-model="selectedDate" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>

            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="invoices.length">
            <b-row class="mb-2">
                <b-col cols="12" md="9" class="d-flex flex-column flex-fill">
                    <h4 class="m-0">Daily Payment Report</h4>
                </b-col>
                <b-col cols="12" md="3" class="d-flex flex-column justify-items-center align-items-end">
                    <b-card no-body>
                        <p class="mb-0 p-3"><strong>Total Received:</strong> {{ totalAmount | currencyFormat }}</p>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple>
                        <b-thead>
                            <b-tr>
                                <b-th>Invoice</b-th>
                                <b-th class="text-center">Customer</b-th>
                                <b-th class="text-center">Order</b-th>
                                <b-th class="text-right">Total Received</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(invoice, invoiceInd) in invoices" :key="invoiceInd">
                                <b-td><a :href="'/pdf/invoice/'+invoice.id" target="_blank" class="router-link-active" size="sm" v-b-popover.hover.bottom="'View the PDF version of the invoice.'">Invoice #{{ invoice.id }}</a></b-td>
                                <b-td class="text-center"><router-link v-b-popover.hover.bottom="'View Customer.'" :to="{name: 'dash.customer.view', params: { id: invoice.order.customer_id }}">{{ [invoice.order.customer.forename, invoice.order.customer.surname].join(' ') }}</router-link></b-td>
                                <b-td class="text-center"><router-link v-b-popover.hover.bottom="'View Order.'" :to="{name: 'dash.orders.view', params: { id: invoice.order.id }}">Order #{{ invoice.order.id }}</router-link></b-td>
                                <b-td class="text-right">{{ invoice.amount | currencyFormat }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

        </b-card>

        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
      this.selectedDate = this.moment().format('YYYY-MM-DD')
  },
  data: () => ({
      selectedDate: null,

      totalAmount: 0,

      invoices: [],
      updating: false,
  }),
  computed: {
      valid() {
          return Boolean(this.selectedDate)
      }
  },
  methods: {

    update() {
      if(!this.updating && this.valid) {
        this.updating = true
        window.axios.post('/reports/daily-payment-report', {
            'date': this.selectedDate,
        }).then(response => {
          this.invoices = response.data.invoices
          this.totalAmount = response.data.totalAmount
          this.updating = false
        })
      }
    }
    
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
