<template>
  <b-container fluid>
    <ResourceList
      can-delete create-to="dash.orders.create" resource="order" soft-deletes title="Orders" :has-filters="true"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #filters>
        <b-row>
          <b-col cols="12">
              <b-form-group label="Order Status">
                <b-form-select v-model="orderStatus" @change="onPaginate" :options="[
                  { value: 'All', text: 'All' },
                  { value: 'Active', text: 'Active' },
                  { value: 'Cancelled', text: 'Cancelled' },
                ]"/>
              </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p v-if="!item.cancelled_at" class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'dash.orders.view', params: { id: item.id }}">#{{ item.id }} {{ [item.customer.forename, item.customer.surname].join(' ') }}</router-link></p>
            <p v-if="item.cancelled_at" class="h5 flex-fill mb-1 text-primary">#{{ item.id }} {{ [item.customer.forename, item.customer.surname].join(' ') }}</p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.created_at" /></p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0 flex-fill">{{ item.email }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-if="!item.cancelled_at && (item.remaining_amount > 0)" v-b-popover.hover.bottom="'Raise an invoice for this order.'" :to="{name: 'dash.invoices.create', params: { order_id: item.id }}"><b-icon icon="credit-card" /> Raise Invoice</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this order.'" :to="{name: 'dash.orders.view', params: { id: item.id }}"><b-icon icon="person" /> View</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchMany(this.$route.query)
  },
  data: () => ({
    orderStatus: 'All',
  }),

  mounted() {
    if(this.$route.query.order_id) { 
      // Set the order_id in the data
    }
  },

  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('orders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('orders', ['deleteMany', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({
          ...this.$route.query,
          page,
          orderStatus: this.orderStatus
        })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query, orderStatus: this.orderStatus })
      }
    }
  }
}
</script>

<style>
 
</style>
