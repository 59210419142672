<template>
  <b-container fluid>
    <ResourceList
      :can-create="false" :has-search="true" resource="installation" soft-deletes title="Installation Routes"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :can-select-many="false" :has-buttons="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <b-row class="flex-fill">
          <b-col md="6" class="order-2 order-md-1">
            <div class="p-3">
              <p class="h5 mb-0">#{{ item.id }} {{ item.order.customer.forename }} {{ item.order.customer.surname }}</p>
              <div class="d-flex flex-column mt-2">
                <small>{{ fullAddress(item.order.shipping_address, ' | ') }}</small>
              </div>
              <small>{{ item.product.name }}</small><br>
              <p class="mb-0 mt-1">{{ installDate(item.install_at) }}</p>
              <div class="mt-2">
                <b-badge pill variant="warning" class="mb-0 mr-3" v-if="item.electrics_required">Electrics Required</b-badge>
                <b-badge pill variant="warning" class="mb-0" v-if="item.crane_required">Crane Required</b-badge>
              </div>
              <div class="mt-2">
                <b-form-textarea placeholder="Installation Route Comments" v-model="item.route_comments"></b-form-textarea>
              </div>
              <div class="mt-2">
                <b-button :to="{name: 'dash.orders.view', params: { id: item.order_id }}" variant="secondary" size="sm" class="mr-1 mt-2">View Order</b-button>
                <b-button variant="danger" size="sm" class="mr-1 mt-2" @click="dismissVideo(item)"><fa-icon icon="times"/> Dismiss</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="order-1 order-md-2">
            <b-embed
              type="video"
              controls
              aspect="16by9"
              allowfullscreen>
              <source :src="item.video.url">
            </b-embed>
          </b-col>
        </b-row>


      </template>
    </ResourceList>
    
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchManyRoutes(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList,
  },
  computed: {
    ...mapGetters('installations', ['data', 'isDeleting', 'isLoading', 'pagination']),
  },
  methods: {
    ...mapActions('installations', ['batchDelete', 'fetchManyRoutes']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyRoutes(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page }
        })
        await this.fetchManyRoutes({ ...this.$route.query, page })
      }
    },
    async doPaginate (page) {
        await this.fetchManyRoutes({  ...this.$route.query })
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyRoutes({ ...this.$route.query, query })
      }
    },

    dismissVideo(orderItem) {
      window.axios.post(`installations/routes/dismiss/${orderItem.id}`, {
        'route_comments': orderItem.route_comments
      }).then(response => {
        this.doPaginate()
      })
    },

    fullAddress(address, delimiter) {
      var returnAddress = ''
      if(address.address_line_1) returnAddress += `${address.address_line_1}${delimiter}`
      if(address.address_line_2) returnAddress += `${address.address_line_2}${delimiter}`
      if(address.city) returnAddress += `${address.city}${delimiter}`
      if(address.county) returnAddress += `${address.county}${delimiter}`
      if(address.postcode) returnAddress += `${address.postcode}`
      return returnAddress
    },
    installDate: function(date) {
      if (!date) return 'No Installation Date.';
      return 'Installation Date: ' + this.moment(date).format('DD/MM/YYYY');
    },
  },
}
</script>

<style lang="scss">

  .no-outline {
    outline: none !important;
    box-shadow: none !important;
  }

  #address-modal {
    .modal-content {
      background-color: #F4F4F6 !important;
      border: 3px solid $primary;
    }

    .modal-header {
      h3 {
        color: $primary;
      }
    }

    .modal-body {
      padding: 0!important;
    }

    .modal-header,
    .modal-footer {
      color: $secondary;
      background-color: $primary;
      border-radius: 0;
    }

    .modal-header .close {
      color: #fff;
      text-shadow: none;
      opacity: 1;
    }
  }

  .bg-success:hover {
    background-color: #3c9150!important;
  }

  .bg-warning {
    background-color: #e67e22!important;
  }

  .bg-warning:hover {
    background-color: #e47718!important;
  }

  .bg-danger:hover {
    background-color: #b82c3a!important;
  }

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }

</style>
