<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" md="4">
        <h1 class="font-weight-bold mb-1">Sales Map</h1>
      </b-col>
      <b-col cols="12" md="8">
          <b-row>
              <b-col cols="12" md="6" class="mt-2">
                  <b-form-datepicker v-model="fromDate" @input="doFetchAll"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="6" class="mt-2">
                  <b-form-datepicker v-model="toDate" @input="doFetchAll"></b-form-datepicker>
              </b-col>
          </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-3 h-100">
      <b-col cols="12">
        <GmapMap
          :center="{ lat: 54.5, lng: -3 }"
          :zoom="6.5"
          map-type-id="terrain"
          style="width: 100%; height: 75vh"
        >
          <template v-if="data.length">
          <GmapMarker
            :zIndex="2"
            icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
            :position="{ lat:Number(singleDisplayItem.shipping_address.latitude), lng: Number(singleDisplayItem.shipping_address.longitude) }"
            v-for="(singleDisplayItem, singleDisplayItemKey) in data" :key="`mapmarker${singleDisplayItemKey}`"/>
          </template>
          <template v-if="customerData.length">
          <GmapMarker
            :zIndex="1"
            icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
            :position="{ lat:Number(customer.shipping_address.latitude), lng: Number(customer.shipping_address.longitude) }"
            v-for="(customer, customerKey) in customerData" :key="`customer-mapmarker${customerKey}`"/>
          </template>
        </GmapMap>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
      fromDate: null,
      toDate: null,
  }),
  created () {
    this.fromDate = this.moment().startOf('month').toDate()
    this.toDate = this.moment().endOf('month').toDate()
    this.doFetchAll()
  },
  components: {
    FriendlyDate,
  },
  computed: {
    ...mapGetters('orders', ['data', 'isLoading']),
    ...mapGetters('customers', {
        'customerData': 'data',
        'customerIsLoading': 'isLoading'
      }),
  },
  methods: {
    ...mapActions('orders', ['fetchSalesMap']),
    ...mapActions('customers', ['fetchManyLeads']),

    doFetchAll() {
        this.fetchSalesMap({
            from: this.moment(this.fromDate).format('DD/MM/YYYY'),
            to: this.moment(this.toDate).format('DD/MM/YYYY'),
        }),
        this.fetchManyLeads({
            from: this.moment(this.fromDate).format('DD/MM/YYYY'),
            to: this.moment(this.toDate).format('DD/MM/YYYY'),
        })
    }

  }
}
</script>

<style lang="scss">
  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }
</style>
