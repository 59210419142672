import pagination from '../../utils/pagination'
import {
  SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST, SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS, SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE,
  SUPPLY_PRODUCTS_FETCH_MANY_REQUEST, SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS, SUPPLY_PRODUCTS_FETCH_MANY_FAILURE,
  SUPPLY_PRODUCTS_FETCH_ALL_REQUEST, SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS, SUPPLY_PRODUCTS_FETCH_ALL_FAILURE,
  SUPPLY_PRODUCTS_FETCH_REQUEST, SUPPLY_PRODUCTS_FETCH_SUCCESS, SUPPLY_PRODUCTS_FETCH_FAILURE,
  SUPPLY_PRODUCTS_SAVE_REQUEST, SUPPLY_PRODUCTS_SAVE_SUCCESS, SUPPLY_PRODUCTS_SAVE_FAILURE,

  EMPTY_SUPPLY_PRODUCTS,
  CLEAR_ERRORS
} from './types'

export default {
  [SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [SUPPLY_PRODUCTS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [SUPPLY_PRODUCTS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SUPPLY_PRODUCTS_FETCH_ALL_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [SUPPLY_PRODUCTS_FETCH_ALL_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SUPPLY_PRODUCTS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [SUPPLY_PRODUCTS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [SUPPLY_PRODUCTS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [SUPPLY_PRODUCTS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [SUPPLY_PRODUCTS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [SUPPLY_PRODUCTS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_SUPPLY_PRODUCTS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
