<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Sales Calendar</h1>
      </b-col>
    </b-row>

    <b-row>
        <b-col cols="12">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <SalesFullCalendar
                            :data="calendarData"
                            @getData="getCalendarData"
                            @eventClick="eventClicked"
                        ></SalesFullCalendar>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import SalesFullCalendar from '../../components/SalesFullCalendar'

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
    startDate: null,
    endDate: null,
  }),
  components: {
    FriendlyDate,
    ResourceList,
    SalesFullCalendar
  },
  computed: {
    ...mapGetters('orders', [ 'calendarData', 'isDeleting', 'isLoading', 'pagination' ])
  },
  methods: {

    ...mapActions('orders', ['fetchManyCalendar']),
    
    eventClicked(payload) {
        this.$router.push({name: 'dash.orders.view', params: { id: payload.event._def.publicId }})
    },

    async getCalendarData (payload) {
        if(payload) {
            this.startDate = payload.start_date
            this.endDate = payload.end_date
        }
        this.fetchManyCalendar({
            'start_date': this.startDate,
            'end_date': this.endDate
        })
    },
  },
}
</script>
