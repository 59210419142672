<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Order</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">Customer</p>
                    <p class="mb-0 text-muted">Who is ordering the product?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <customer-search :error-feed="errors.errors" :error-key="'customer_id'" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="customer" @input="customerUpdated" v-model="order.customer" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">User</p>
                    <p class="mb-0 text-muted">Who made this sale?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <user-search :error-feed="errors.errors" :error-key="'user_id'" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="user" @input="userUpdated" v-model="order.user" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="11" class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0">Billing Address</p>
                  </b-col>
                  <b-col cols="1" class="d-flex flex-row flex-fill align-items-start justify-content-center">
                    <fa-icon class="text-danger" icon="flag" v-if="computedAddressesAreDifferent"></fa-icon>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col cols="12">
                    <b-form-group label="Postcode Search" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.postcode')">
                      <postcode-search :error-feed="errors.errors" error-key="billing_address.postcode" :disabled="isSaving" @address="address => { this.order.billing_address = address }" v-model="order.billing_address.postcode"></postcode-search>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.address_line_1')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.address_line_1')" v-model="order.billing_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.address_line_2')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.address_line_2')" v-model="order.billing_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.city')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.city')" v-model="order.billing_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'billing_address.county')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'billing_address.county')" v-model="order.billing_address.county" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>

                <b-row>
                  <b-col cols="11" class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0">Shipping &amp; Installation Address</p>
                  </b-col>
                  <b-col cols="1" class="d-flex flex-row flex-fill align-items-start justify-content-center">
                    <fa-icon class="text-danger" icon="flag" v-if="computedAddressesAreDifferent"></fa-icon>
                  </b-col>
                </b-row>

                <b-row class="py-3">
                  <b-col cols="12">
                    <b-button size="sm" @click="setShippingAddressAsBilling" variant="primary">Copy Billing Address</b-button>
                  </b-col>
                </b-row>

                <b-row class="mt-4">
                  <b-col cols="12">
                    <b-form-group label="Postcode Search" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.postcode')">
                      <postcode-search :error-feed="errors.errors" error-key="shipping_address.postcode" :disabled="isSaving" @address="address => { this.order.shipping_address = address }" v-model="order.shipping_address.postcode"></postcode-search>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_1')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.address_line_1')" v-model="order.shipping_address.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.address_line_2')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.address_line_2')" v-model="order.shipping_address.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.city')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.city')" v-model="order.shipping_address.city" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'shipping_address.county')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'shipping_address.county')" v-model="order.shipping_address.county" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('additional_notes')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['additional_notes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Additional Notes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['additional_notes']">
                  <b-col cols="12">
                    <b-form-group label="Order Notes" :invalid-feedback="validationInvalidFeedback(errors.errors, 'additional_notes')">
                      <b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'additional_notes')" v-model="order.additional_notes" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('products_appointment')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products_appointment'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Order Products</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end" cols="12" md="3">
                    <b-button block size="sm" variant="primary">Total: £{{ orderTotal }}</b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['products_appointment']">
                  <b-col cols="12" v-if="!order.items.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no products selected.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="order.items.length" class="p-4">
                    <product-search :error-feed="errors.errors" :error-key="'items.'+itemInd+'.product_id'" @delete="rmItem(itemInd)" show-delete type="items" :use-display-price="true" :display-price="item.total_price" v-model="order.items[itemInd].product" class="mb-3" @input="itemUpdated(itemInd)" v-for="(item, itemInd) in order.items" :key="'order_item_'+item.id+'_'+itemInd+'_'+item.product_id+'_'+item.supply_product.id">
                      <template #form-area>
                        <hr>
                        <b-card>
                          <b-row>
                            <b-col cols="12" class="pt-1 pb-4">
                              <supply-product-search :error-feed="errors.errors" :error-key="'items.'+itemInd+'.supply_product.id'" :wait-for-input="false" type="supply-products" v-model="order.items[itemInd].supply_product" :order-id="order.id" :product-id="order.items[itemInd].product_id" @input="updateUsedSupplyProductsArray" :used-supply-products="usedSupplyProducts"/>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Price Inc VAT." :invalid-feedback="validationInvalidFeedback(errors.errors, 'items.'+itemInd+'.total_price')">
                                <b-form-input :state="validationState(errors.errors, 'items.'+itemInd+'.total_price')"
                                  v-model="item.total_price"
                                  @keyup="setOrderTotal"
                                  placeholder="Price Inc. VAT"
                                  size="sm"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col cols="12">
                              <b-card>
                                <b-row>
                                  <b-col cols="12" class="d-flex">
                                    <div class="d-flex flex-fill flex-row align-items-center justify-content-start">
                                      <h4 class="mb-0">Extras</h4>
                                    </div>
                                    <div class="d-flex flex-row align-items-center justify-content-end" v-if="!item.extras.length">
                                      <b-button size="sm" @click.prevent="addItemExtra(itemInd)" variant="primary"><fa-icon icon="plus" /> Add Extra</b-button>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row v-if="item.extras.length">
                                  <b-col cols="12">
                                    <hr>
                                  </b-col>
                                </b-row>
                                <b-row class="mt-3" v-if="item.extras.length">
                                  <b-col cols="12">
                                    <extra-search :error-feed="errors.errors" :error-key="'items.'+itemInd+'.extras.'+extraInd+'.extra_id'" @delete="rmItemExtra(itemInd, extraInd)" show-delete type="extras" :use-display-price="true" :display-price="extra.price" v-model="order.items[itemInd].extras[extraInd].extra" class="mb-3" @input="itemExtraUpdated(itemInd, extraInd)" v-for="(extra, extraInd) in item.extras" :key="'extra_'+extra.id+'_'+extra.extra_id">
                                      <template #form-area>
                                        <hr>
                                        <b-row>
                                          <b-col cols="12">
                                            <b-form-group label="Price Inc VAT." :invalid-feedback="validationInvalidFeedback(errors.errors, 'items.'+itemInd+'.extras.'+extraInd+'.price')">
                                              <b-form-input :state="validationState(errors.errors, 'items.'+itemInd+'.extras.'+extraInd+'.price')"
                                                v-model="extra.price"
                                                placeholder="Price Inc. VAT"
                                                size="sm"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </template>
                                    </extra-search>
                                  </b-col>
                                </b-row>
                                <b-row v-if="item.extras.length">
                                  <b-col cols="12">
                                    <b-button block @click.prevent="addItemExtra(itemInd)" variant="primary"><fa-icon icon="plus" /> Add Extra</b-button>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <b-form-group label="Install Required">
                                <b-form-radio v-model="item.install_required" :value="1">Install is Required</b-form-radio>
                                <b-form-radio v-model="item.install_required" :value="0">Install is not Required</b-form-radio>
                              </b-form-group>
                              <b-form-group label="Install At" v-if="item.install_required" :invalid-feedback="validationInvalidFeedback(errors.errors, 'items.'+itemInd+'.install_at')">
                                <b-form-datepicker reset-button :state="validationState(errors.errors, 'items.'+itemInd+'.install_at')" v-model="item.install_at" />
                              </b-form-group>
                              <b-form-group label="Colour" v-if="item.install_required">
                                  <b-form-select v-model="item.colour">
                                      <b-form-select-option :value="null">Please select a colour</b-form-select-option>
                                      <b-form-select-option value="#B32727">Dark Red</b-form-select-option>
                                      <b-form-select-option value="#498C4B">Dark Green</b-form-select-option>
                                      <b-form-select-option value="#2E3145">Dark Blue</b-form-select-option>
                                      <b-form-select-option value="#4BB5A2">Turquoise</b-form-select-option>
                                      <b-form-select-option value="#AE48B0">Dark Purple</b-form-select-option>
                                  </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="mb-4" v-if="item.install_required && item.install_at">
                            <b-col cols="12">
                              <b-card>
                                <div class="d-flex flex-row justify-content-between">
                                  <div class="d-flex flex-column justify-content-center">
                                    <p class="h3 mb-0 font-weight-bold text-muted">Team</p>
                                  </div>
                                  <div class="d-flex flex-column flex-lg-row justify-content-center w-25">
                                    <team-search :error-feed="errors.errors" :error-key="'items.'+itemInd+'.team_id'" :wait-for-input="false" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="team" @input="teamUpdated(item)" v-model="item.team" />
                                  </div>
                                </div>
                              </b-card>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="6">
                              <b-form-group label="Crane Required" v-if="item.install_required">
                                <b-form-radio v-model="item.crane_required" :value="1">Crane is Required</b-form-radio>
                                <b-form-radio v-model="item.crane_required" :value="0">Crane is not Required</b-form-radio>
                              </b-form-group>
                            </b-col>
                            <b-col cols="6">
                              <b-form-group label="Electrics Required" v-if="item.install_required">
                                <b-form-radio v-model="item.electrics_required" :value="1">Electrics is Required</b-form-radio>
                                <b-form-radio v-model="item.electrics_required" :value="0">Electrics is not Required</b-form-radio>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="item.crane_required">
                            <b-col cols="12">
                              <b-form-group label="Crane Time" :invalid-feedback="validationInvalidFeedback(errors.errors, 'items.'+itemInd+'.crane_time')">
                                <b-form-input :state="validationState(errors.errors, 'items.'+itemInd+'.crane_time')"
                                  v-model="item.crane_time"
                                  placeholder="HH:mm"
                                  size="sm"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </product-search>
                  </b-col>
                  <b-col cols="12" class="d-flex">
                    <b-button block @click.prevent="addItem" variant="primary"><fa-icon icon="plus" /> Add Item</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="!$route.params.id || financePackages.length">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('payment_details')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['payment_details'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Payment Details</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['payment_details']">
                  <b-col cols="12" v-if="financePackages.length">
                    <b-form-group label="Finance Package" :invalid-feedback="validationInvalidFeedback(errors.errors, 'finance_package')">
                      <b-form-select v-model="order.finance_package_id">
                        <b-form-select-option :value="null">No Finance Required</b-form-select-option>
                        <b-form-select-option :value="financePackage.id" :key="financePackageInd" v-for="(financePackage, financePackageInd) in financePackages">{{ financePackage.name }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-if="order.finance_package_id">
                    <b-form-group label="Loan ID" :invalid-feedback="validationInvalidFeedback(errors.errors, 'loan_id')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'loan_id')" v-model="order.loan_id" />
                    </b-form-group>
                    <b-form-group label="Finance Amount" :invalid-feedback="validationInvalidFeedback(errors.errors, 'finance_amount')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'finance_amount')" v-model="order.finance_amount" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-row class="mb-4" v-if="!$route.params.id">
                      <b-col cols="12">
                        <b-card>
                          <b-row>
                            <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('initial_invoice')" role="button">
                              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['initial_invoice'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                              <div class="d-flex flex-column flex-fill ml-4">
                                <p class="h4 font-weight-bold mb-0">Initial Invoice</p>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="mt-4" v-if="!collapsed['initial_invoice']">
                            <b-col cols="12">
                              <b-form-group label="Invoice Amount" :invalid-feedback="validationInvalidFeedback(errors.errors, 'initial_invoice.amount')">
                                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'initial_invoice.amount')" v-model="initialInvoice.amount" />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group label="Invoice Due" :invalid-feedback="validationInvalidFeedback(errors.errors, 'initial_invoice.invoice_due')">
                                <b-form-datepicker v-model="initialInvoice.invoice_due" />
                              </b-form-group>
                            </b-col>
                            <b-col class="d-flex flex-column justify-content-end" cols="12">
                              <b-row>
                                <b-col cols="12" md="7">
                                  <b-form-group v-if="initialInvoice.paid" label="Payment Method" :invalid-feedback="validationInvalidFeedback(errors.errors, 'payment_method')" class="mb-0">
                                    <b-form-select v-model="initialInvoice.payment_method" :state="validationState(errors.errors, 'payment_method')">
                                      <b-form-select-option disabled :value="null">Please select a payment method</b-form-select-option>
                                      <b-form-select-option value="Manual">Manual</b-form-select-option>
                                      <b-form-select-option value="Bank Transfer">Bank Transfer</b-form-select-option>
                                      <b-form-select-option value="Card Payment">Card Payment</b-form-select-option>
                                      <b-form-select-option value="Finance">Finance</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-card class="mb-3">
                                    <b-checkbox v-model="initialInvoice.paid">Invoice Paid?</b-checkbox>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col cols="12">
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import CustomerSearch from '../../components/CustomerSearch'
import ProductSearch from '../../components/ProductSearch'
import ExtraSearch from '../../components/ExtraSearch'
import SupplyProductSearch from '../../components/SupplyProductSearch'
import PostcodeSearch from '../../components/PostcodeSearch'
import UserSearch from '../../components/UserSearch'
import has from 'lodash/has'
import TeamSearch from '../../components/TeamSearch'


export default {
  mixins: [validation,momentMixin,currentUser],
  components: { CustomerSearch, ProductSearch, ExtraSearch, UserSearch, SupplyProductSearch, TeamSearch, PostcodeSearch },
  created () {
    if(this.$route.params.supply_product) {
      this.order.items.push({
        id: null,
        product_id: null,
        product: { id: this.$route.params.supply_product.product_id },
        install_at: null,
        install_required: false,
        crane_required: false,
        crane_time: null,
        electrics_required: false,
        colour: null,
        price: 0,
        supply_product: { id: this.$route.params.supply_product.id },
        extras: [],
        team_id: null,
        team: { id: null }
      })

      this.collapsed.products_appointment = false
    }
    if (this.$route.params.customer_id) {
      this.order.customer_id = this.$route.params.customer_id
      this.order.customer.id = this.$route.params.customer_id
      if(this.currentUser.act_as_user_id == null) {
        this.order.user.id = this.currentUser.id
        this.order.user_id = this.currentUser.id
      }else{
        this.order.user.id = this.currentUser.act_as_user_id;
        this.order.user_id = this.currentUser.act_as_user_id
      }
    }else if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }else{
      if(this.currentUser.act_as_user_id == null) {
        this.order.user.id = this.currentUser.id
        this.order.user_id = this.currentUser.id
      }else{
        this.order.user.id = this.currentUser.act_as_user_id;
        this.order.user_id = this.currentUser.act_as_user_id
      }
    }
    this.fetchManyFinancePackages('','').then(response => {
      this.financePackages = response.data.data
    });
  },
  computed: {
    ...mapGetters('orders', ['errors', 'isLoading', 'isSaving', 'single']),

    computedAddressesAreDifferent() {
      return !(
          (this.order.shipping_address.address_line_1 == this.order.billing_address.address_line_1) &&
          (this.order.shipping_address.address_line_2 == this.order.billing_address.address_line_2) &&
          (this.order.shipping_address.city == this.order.billing_address.city) &&
          (this.order.shipping_address.county == this.order.billing_address.county) &&
          (this.order.shipping_address.postcode == this.order.billing_address.postcode)
        )
    }
  },
  data () {
    return {
      collapsed: {
        order_details: !this.$route.params.id,
        billing_address: !this.$route.params.id,
        shipping_address: !this.$route.params.id,
        products_appointment: !this.$route.params.id,
        initial_invoice: !this.$route.params.id,
        additional_notes: !this.$route.params.id,
        payment_details: !this.$route.params.id
      },

      orderTotal: 0,

      order: {
        id: null,
        billing_address: {},
        shipping_address: {},
        items: [],
        user_id: null,
        user: { id: null },
        finance_package_id: null,
        finance_amount: null,
        loan_id: null,
        additional_notes: null,
        customer_id: null,
        customer: { id: null },
      },
      initialInvoice: {
        amount: null,
        payment_url: null,
        invoice_due: null,
        payment_method: null,
        paid: false,
      },
      financePackages: [],
      usedSupplyProducts: [],
    }
  },
  methods: {

    ...mapActions('orders', ['fetch', 'save']),

    ...mapActions('finance-packages', {'fetchManyFinancePackages': 'fetchMany'}),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit () {
      let data = {
        id: this.$route.params.id,
        deposit_amount: this.order.deposit_amount,
        billing_address: this.order.billing_address,
        shipping_address: this.order.shipping_address,
        items: this.order.items,
        user_id: this.order.user_id,
        finance_package_id: this.order.finance_package_id,
        finance_amount: this.order.finance_amount,
        additional_notes: this.order.additional_notes,
        loan_id: this.order.loan_id,
        customer_id: this.order.customer_id,
      }

      if(
        !this.$route.params.id &&
        this.initialInvoice.amount &&
        // this.initialInvoice.payment_url &&
        this.initialInvoice.invoice_due
      ) {
        data['initial_invoice'] = this.initialInvoice
      } 

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.orders.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Order #${response.data.id}`,
              text: `The order has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },

    setOrderTotal() {
      var orderTotal = 0;
      if(this.order.items) {
        this.order.items.forEach(item => {
          if(!isNaN(item.total_price)) {
            orderTotal += Number(item.total_price)
          }
        });
        this.orderTotal = orderTotal
      }
    },

    setShippingAddressAsBilling() {
      this.order.shipping_address = { ...this.order.billing_address }
    },

    addItem() {
      this.order.items.push({
        id: null,
        product_id: null,
        product: null,
        install_at: null,
        install_required: false,
        crane_required: false,
        crane_time: null,
        electrics_required: false,
        colour: null,
        price: 0,
        supply_product: { id: null },
        extras: [],
        team_id: null,
        team: { id: null }
      })
    },

    rmItem(itemInd) {
      this.order.items.splice(itemInd, 1)
      this.updateUsedSupplyProductsArray()
      this.setOrderTotal()
    },

    addItemExtra(itemInd) {
      this.order.items[itemInd].extras.push({
        id: null,
        extra_id: null,
        extra: { id: null },
        name: null,
        price: null,
        cost_price: null,
      })
    },

    setItemTotal(ind) {
      var productIncVat = this.order.items[ind].product.price ?? 0
      var extrasTotal = 0;
      this.order.items[ind].extras.forEach(orderItemExtra => {
        extrasTotal += Number(orderItemExtra.price)
      })
      this.order.items[ind].total_price = (Number(productIncVat) + Number(extrasTotal))
      this.setOrderTotal()
    },

    rmItemExtra(itemInd, extraInd) {
      var decreasePrice = this.order.items[itemInd].extras[extraInd].price
      this.order.items[itemInd].extras.splice(extraInd, 1)
      this.order.items[itemInd].total_price = this.order.items[itemInd].total_price - decreasePrice
      this.setOrderTotal()
    },

    customerUpdated() {
      this.order.billing_address = {...this.order.customer.billing_address}
      this.order.shipping_address = {...this.order.customer.shipping_address}
      this.order.customer_id = this.order.customer.id
    },

    userUpdated() {
      this.order.user_id = this.order.user.id
    },

    itemUpdated(ind) {
      if(!this.$route.params.id || this.order.items[ind].product_id != this.order.items[ind].product.id) {
        if(this.order.items[ind].product.id != null) {
          var updateItemTotal = false;
          
          if(this.order.items[ind].product_id != this.order.items[ind].product.id) {
            updateItemTotal = true;
          }

          this.order.items[ind].total_price = this.order.items[ind].product.price
          this.order.items[ind].product_id = this.order.items[ind].product.id

          if(updateItemTotal) {
            this.setItemTotal(ind) 
            this.setOrderTotal()
          }
        }
      }
    },

    itemExtraUpdated(itemInd, extraInd) {
      // This code runs when we make changes to an extra.
      var oldExtra = this.order.items[itemInd].extras[extraInd];
      if(oldExtra.extra_id == null) {
        this.order.items[itemInd].total_price = Number(this.order.items[itemInd].total_price) + Number(this.order.items[itemInd].extras[extraInd].extra.price)
        this.setOrderTotal()
      }
      this.order.items[itemInd].extras[extraInd].extra_id = this.order.items[itemInd].extras[extraInd].extra.id
      this.order.items[itemInd].extras[extraInd].name = this.order.items[itemInd].extras[extraInd].extra.name
      this.order.items[itemInd].extras[extraInd].price = this.order.items[itemInd].extras[extraInd].extra.price
      this.order.items[itemInd].extras[extraInd].cost_price = this.order.items[itemInd].extras[extraInd].extra.cost_price
    },

    updateUsedSupplyProductsArray() {
      this.usedSupplyProducts = this.order.items.map(item => {
        if(has(item, 'supply_product.id')) {
          if(item.supply_product.id) {
            return item.supply_product.id
          }
        }
      }).filter(item => item?1:0)
    },

    teamUpdated(item) {
      item.team_id = item.team.id;
    },
  },
  watch: {
    single () {
      this.order = this.single
      this.setOrderTotal()
      if(this.order.items) {
        this.order.items.forEach((item, itemInd) => {
          if(item.supply_product == null) item.supply_product = { id: null }
          if(item.team == null) item.team = { id: null }
        })
        this.updateUsedSupplyProductsArray()
      }
    },
  }
}
</script>

<style>

</style>
