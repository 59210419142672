<template>
  <b-container fluid>
    <ResourceList
      :can-delete="false" :can-create="false" :can-select-many="false" resource="order" soft-deletes title="Finance"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :has-buttons="false"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-row flex-fill">
            <p v-if="!item.cancelled_at" class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'dash.orders.update', params: { id: item.id }}">{{ [item.customer.forename, item.customer.surname].join(' ') }}</router-link></p>
            <p v-if="item.cancelled_at" class="h5 flex-fill mb-1 text-primary">#{{ item.id }} {{ [item.customer.forename, item.customer.surname].join(' ') }}</p>
            <p class="align-self-end mb-1 text-muted"><FriendlyDate :date="item.created_at" /></p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <small class="mb-0 flex-fill text-muted">{{ item.remaining_amount | currencyFormat }}</small>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.bottom="'Generate a paid invoice for this order.'" @click="markAsPaid(item)"><b-icon icon="pen" /> Mark as Paid</b-button>
            <b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this order.'" :to="{name: 'dash.orders.view', params: { id: item.id }}"><b-icon icon="person" /> View</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchManyFinance(this.$route.query)
  },

  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('orders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('orders', ['deleteMany', 'fetchManyFinance']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyFinance(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyFinance({
          ...this.$route.query,
          page,
        })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyFinance({ ...this.$route.query, query })
      }
    },
    markAsPaid(item) {
      window.axios.post(`/orders/finance/mark-paid/${item.id}`).then(response => {
        this.fetchManyFinance(this.$route.query)
      })
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>

</style>
