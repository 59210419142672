import {
  INSTALLATIONS_BATCH_DELETE_REQUEST,
  INSTALLATIONS_BATCH_DELETE_SUCCESS,
  INSTALLATIONS_BATCH_DELETE_FAILURE,
  INSTALLATIONS_FETCH_MANY_REQUEST,
  INSTALLATIONS_FETCH_MANY_SUCCESS,
  INSTALLATIONS_FETCH_MANY_FAILURE,
  INSTALLATIONS_FETCH_ALL_REQUEST,
  INSTALLATIONS_FETCH_ALL_SUCCESS,
  INSTALLATIONS_FETCH_ALL_FAILURE,
  INSTALLATIONS_CALENDAR_REQUEST,
  INSTALLATIONS_CALENDAR_SUCCESS,
  INSTALLATIONS_CALENDAR_FAILURE,
  INSTALLATIONS_FETCH_REQUEST,
  INSTALLATIONS_FETCH_SUCCESS,
  INSTALLATIONS_FETCH_FAILURE,
  INSTALLATIONS_SAVE_REQUEST,
  INSTALLATIONS_SAVE_SUCCESS,
  INSTALLATIONS_SAVE_FAILURE,
  INSTALLATIONS_PROCESS_REQUEST,
  INSTALLATIONS_PROCESS_SUCCESS,
  INSTALLATIONS_PROCESS_FAILURE,

  EMPTY_INSTALLATIONS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(INSTALLATIONS_BATCH_DELETE_REQUEST)
    return window.axios.post('/installations', { _method: 'DELETE', ids }).then(response => {
      commit(INSTALLATIONS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, filterName = '', longitude = '', latitude = '', installationDate = '', selectedDate = '', team = null }) {
    commit(INSTALLATIONS_FETCH_MANY_REQUEST)
    return window.axios.get('/installations', { params: { query, page, filterName, longitude, latitude, installationDate, selectedDate, team } }).then(response => {
      commit(INSTALLATIONS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchAll ({ commit }, { from, to }) {
    commit(INSTALLATIONS_FETCH_ALL_REQUEST)
    return window.axios.get('/installations/all', {params: { from, to }}).then(response => {
      commit(INSTALLATIONS_FETCH_ALL_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_ALL_FAILURE, error)
      throw error
    })
  },
  fetchManyRoutes ({ commit }, { query = '', page = 1 }) {
    commit(INSTALLATIONS_FETCH_MANY_REQUEST)
    return window.axios.get('/installations/routes', { params: { query, page } }).then(response => {
      commit(INSTALLATIONS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyPendingRoutes ({ commit }, { query = '', page = 1 }) {
    commit(INSTALLATIONS_FETCH_MANY_REQUEST)
    return window.axios.get('/installations/routes/pending', { params: { query, page } }).then(response => {
      commit(INSTALLATIONS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManySignedOff ({ commit }, { query = '', page = 1 }) {
    commit(INSTALLATIONS_FETCH_MANY_REQUEST)
    return window.axios.get('/installations/signed-off', { params: { query, page } }).then(response => {
      commit(INSTALLATIONS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchManyCalendar ({ commit }, { start_date, end_date, teams = [], query }) {
    commit(INSTALLATIONS_CALENDAR_REQUEST)
    return window.axios.get('/installations/calendar', { params: { start_date, end_date, teams, query } }).then(response => {
      commit(INSTALLATIONS_CALENDAR_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_CALENDAR_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(INSTALLATIONS_FETCH_REQUEST)
    return window.axios.get(`/installations/${id}`).then(response => {
      commit(INSTALLATIONS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INSTALLATIONS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, installation) {
    const path = installation.id ? `/installations/${installation.id}` : '/installations'
    const method = installation.id ? 'put' : 'post'

    commit(INSTALLATIONS_SAVE_REQUEST)
    return window.axios[method](path, installation).then(response => {
      commit(INSTALLATIONS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(INSTALLATIONS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(INSTALLATIONS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  process ({ commit }, installation) {
    const path = `/installations/${installation.id}/process`
    const method = 'post'

    commit(INSTALLATIONS_PROCESS_REQUEST)
    return window.axios[method](path, installation).then(response => {
      commit(INSTALLATIONS_PROCESS_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(INSTALLATIONS_PROCESS_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(INSTALLATIONS_PROCESS_FAILURE, { error })
      }
      throw error
    })
  },
  doSignOff ({ commit }, {installationId, installation}) {
    const path = `/installations/${installationId}/sign-off`
    const method = 'post'

    commit(INSTALLATIONS_PROCESS_REQUEST)
    return window.axios[method](path, installation, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      commit(INSTALLATIONS_PROCESS_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(INSTALLATIONS_PROCESS_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(INSTALLATIONS_PROCESS_FAILURE, { error })
      }
      throw error
    })
  },
  doCreateInstallationNote({ commit }, { installation, note }) {
    return window.axios.post(`/installations/${installation}/add-note`, { note })
      .then(response => {
        return response
      })
  },
  empty({ commit }) {
    commit(EMPTY_INSTALLATIONS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
