<template>

    <b-row>
        <b-col cols="12">
            <FullCalendar ref="calendar" :options="calendarOptions">
                <template #eventContent="arg">
                    <div class="p-1" v-if="arg.event._def.extendedProps.type == 'installation'" :id="`installation-event-${arg.event._def.publicId}`">
                        <div class="fc-event-title text-wrap">
                            <div class="d-flex flex-column">
                                <span>#{{ arg.event._def.extendedProps.installation.id }}</span>
                                <span class="font-weight-bold">{{ arg.event._def.title }}</span>
                                <span>{{ arg.event._def.extendedProps.installation.order.shipping_address.postcode }}</span>
                            </div>
                        </div>
                        <div>
                            <small v-if="arg.event._def.extendedProps.installation.crane_required" class="font-italic">Crane</small>
                            <small v-if="arg.event._def.extendedProps.installation.crane_required && arg.event._def.extendedProps.installation.electrics_required" class="font-italic"> | </small>
                            <small v-if="arg.event._def.extendedProps.installation.electrics_required" class="font-italic">Electrics</small>
                        </div>
                        <b-tooltip placement="right" :target="`installation-event-${arg.event._def.publicId}`">
                            <div class="d-flex flex-column align-items-start flex-wrap m-2">
                                <div class="mb-2">
                                    <h5 class="text-left mb-0">Installation #{{ arg.event._def.extendedProps.installation.id }}</h5>
                                    <p class="text-left mb-0">#{{ arg.event._def.extendedProps.installation.order.customer.id }} {{ arg.event._def.extendedProps.installation.order.customer.forename }} {{ arg.event._def.extendedProps.installation.order.customer.surname }}</p>
                                </div>
                                <div>
                                    <h5>Delivery Address</h5>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.installation.order.shipping_address.address_line_1 }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.installation.order.shipping_address.address_line_2 }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.installation.order.shipping_address.city }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.installation.order.shipping_address.county }}</p>
                                    <p class="mb-0 text-left">{{ arg.event._def.extendedProps.installation.order.shipping_address.postcode }}</p>
                                </div>
                            </div>
                        </b-tooltip>
                    </div>
                    <div v-else>
                        <div class="fc-event-time">{{ moment(arg.event._instance.range.start).format('HH:mm') }} - {{ moment(arg.event._instance.range.end).format('HH:mm') }}</div>
                        <div class="fc-event-title">{{ arg.event._def.title }}</div>
                    </div>
                </template>
            </FullCalendar>
        </b-col>
    </b-row>

</template>

<script>
/* eslint-disable */
import FriendlyDate from './FriendlyDate'
import momentMixin from '../mixins/momentMixin'

// Calendar.
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
    components: { FriendlyDate, FullCalendar },
    mixins: [momentMixin],
    data () {
        return {
            calendarOptions: {
                locale: 'gb',
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin
                ],
                headerToolbar: { center: 'dayGridMonth,timeGridWeek,timeGridDay' },
                customButtons: {
                    today: {
                        text: 'today',
                        click: () => {
                            this.getData('today')
                        }
                    },
                    next: {
                        click: () => {
                            this.getData('next')
                        }
                    },
                    prev: {
                        click: () => {
                            this.getData('prev')
                        }
                    }
                },
                selectable: this.selectable,
                select: (info) => { this.$emit('select', info) },
                dateClick: (info) => { this.$emit('dateClick', info) },
                eventClick: (info) => { this.$emit('eventClick', info) },
                dayHeaderContent: (arg) => {
                    if(arg.view.type == 'timeGridDay') {
                        return {
                            html: `<div class="fc-scrollgrid-sync-inner">
                                <a class="fc-col-header-cell-cushion" tabindex="0" onclick="document.dispatchEvent(new Event('custom_dayClickEvent'))">${ arg.text }</a>
                            </div>
                            `
                        }
                    }
                    return arg.text;
                },
                navLinks: true,
                navLinkDayClick: (date, event) => {
                    this.$emit('dayClick', date)
                },
                firstDay: 1,
                allDaySlot: true,
                eventOrder: "sort",
                initialView: 'timeGridWeek',
                events: [],
            }
        }
    },
    mounted() {
        if(this.date == null) { 
            if(localStorage.getItem('installation-full-calendar-date')) {
                this.date = this.moment(localStorage.getItem('installation-full-calendar-date')).toDate()
            }else{
                this.date = this.moment().toDate()
            }
        }
        this.$nextTick().then(() => {
            this.$refs.calendar.getApi().gotoDate(this.date)
            this.getData()
        })

        document.addEventListener('custom_dayClickEvent', event => {
            try {
                this.$emit('dayClick', this.$refs.calendar.getApi().getDate())
            }catch(err) {  }
        })
    },
    props: {
        date: {
            required: false,
            default: null
        },
        data: {
            required: true,
            type: Array,
            default: () => ([])
        },
        installationEvents: {
            required: false,
            type: Array,
            default: () => ([])
        },
        isDeleting: {
            default: false,
            type: Boolean
        },
        isLoading: {
            default: false,
            type: Boolean
        },

        // Calendar Props
        selectable: { default: false, type: Boolean },
    },
    computed: {
        calendarEvents() {
            var calendarEvents = []
            var datesNTimes = {}
            this.data.forEach(event => {
                var installAt = event.install_at.split(' ')[0]

                var teamId = event.team_id ? event.team_id : 'no_team'

                if(!datesNTimes.hasOwnProperty(teamId)) {
                    datesNTimes[teamId] = {}
                }

                var startDate = this.moment(installAt + ' 08:00')
                var endDate = this.moment(installAt + ' 10:00')
                if(datesNTimes[teamId].hasOwnProperty(installAt)) {
                    startDate = this.moment(installAt + ' ' + datesNTimes[teamId][installAt])
                    endDate = this.moment(installAt + ' ' + datesNTimes[teamId][installAt]).add(2, 'hours')
                }

                datesNTimes[teamId][installAt] = endDate.format('HH:MM')
                calendarEvents.push({
                    'id': event.id,
                    'title': (event.team_id ? (event.team.initials ? event.team.initials + ' ' : '' ) : '') + event.order.customer.forename + ' ' + event.order.customer.surname,
                    'start': startDate.toDate(),
                    'end': endDate.toDate(),
                    'allDay': true,
                    'display': 'block',
                    'backgroundColor': event.colour ?? '#2E3145',
                    'borderColor': event.colour ?? '#2E3145',
                    'extendedProps': {
                        'type': 'installation',
                        'installation': event
                    }
                })
            })

            this.installationEvents.forEach(event => {
                var start = this.moment(event.start, 'YYYY-MM-DD HH:mm:ss').toDate()
                var end = this.moment(event.end, 'YYYY-MM-DD HH:mm:ss').toDate()
                
                var allDay = false
                if(this.moment(event.start, 'YYYY-MM-DD') != this.moment(event.end, 'YYYY-MM-DD')) {
                    allDay = true
                }

                calendarEvents.push({
                    'id': event.id,
                    'title': (event.team_id ? (event.team.initials ? event.team.initials + ' ' : '' ) : '') + event.name,
                    'start': start,
                    'end': end,
                    'allDay': allDay,
                    'backgroundColor': event.colour ?? '#4BB5A2',
                    'borderColor': event.colour ?? '#4BB5A2',
                    'extendedProps': {
                        'type': 'event',
                        'event': event
                    }
                })
            })

            return calendarEvents
        },
    },
    methods: {
        getData(method) {
            if(method) this.$refs.calendar.getApi()[method]();
            this.$emit('getData', {
                'start_date': this.moment(this.$refs.calendar.getApi().getDate()).subtract(6, 'days').format('DD/MM/YYYY'),
                'end_date': this.moment(this.$refs.calendar.getApi().getDate()).add(13, 'days').format('DD/MM/YYYY')
            })
            localStorage.setItem('installation-full-calendar-date', this.$refs.calendar.getApi().getDate())
        }
    },
    watch: {
        date(date) {
            if(date) {
                this.$refs.calendar.getApi().gotoDate(date)
            }
        },
        data(data) {
            this.calendarOptions.events = this.calendarEvents
        },
        installationEvents(data) {
            this.calendarOptions.events = this.calendarEvents
        }
    }
}
</script>

<style>

</style>
