<template>
  <router-view />
</template>

<script>
import reviewModule from '../../store/reviews'

export default {
  beforeCreate () {
    this.$store.registerModule('reviews', reviewModule)
  },
  destroyed () {
    this.$store.unregisterModule('reviews')
  }
}
</script>
