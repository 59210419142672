import {
  SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST,
  SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS,
  SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE,
  SUPPLY_PRODUCTS_FETCH_MANY_REQUEST,
  SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS,
  SUPPLY_PRODUCTS_FETCH_MANY_FAILURE,
  SUPPLY_PRODUCTS_FETCH_ALL_REQUEST,
  SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS,
  SUPPLY_PRODUCTS_FETCH_ALL_FAILURE,
  SUPPLY_PRODUCTS_FETCH_REQUEST,
  SUPPLY_PRODUCTS_FETCH_SUCCESS,
  SUPPLY_PRODUCTS_FETCH_FAILURE,
  SUPPLY_PRODUCTS_SAVE_REQUEST,
  SUPPLY_PRODUCTS_SAVE_SUCCESS,
  SUPPLY_PRODUCTS_SAVE_FAILURE,

  EMPTY_SUPPLY_PRODUCTS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(SUPPLY_PRODUCTS_BATCH_DELETE_REQUEST)
    return window.axios.post('/supply-products', { _method: 'DELETE', ids }).then(response => {
      commit(SUPPLY_PRODUCTS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLY_PRODUCTS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, extraParams = {} }) {
    commit(SUPPLY_PRODUCTS_FETCH_MANY_REQUEST)
    return window.axios.get('/supply-products', { params: { query, page, ...extraParams } }).then(response => {
      commit(SUPPLY_PRODUCTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLY_PRODUCTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchAll ({ commit }, { query = '', page = 1, extraParams = {} }) {
    commit(SUPPLY_PRODUCTS_FETCH_ALL_REQUEST)
    return window.axios.get('/supply-products/all', { params: { query, page, ...extraParams } }).then(response => {
      commit(SUPPLY_PRODUCTS_FETCH_ALL_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLY_PRODUCTS_FETCH_ALL_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(SUPPLY_PRODUCTS_FETCH_REQUEST)
    return window.axios.get(`/supply-products/${id}`).then(response => {
      commit(SUPPLY_PRODUCTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLY_PRODUCTS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, product) {
    const path = product.id ? `/supply-products/${product.id}` : '/supply-products'
    const method = product.id ? 'put' : 'post'

    commit(SUPPLY_PRODUCTS_SAVE_REQUEST)
    return window.axios[method](path, product).then(response => {
      commit(SUPPLY_PRODUCTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SUPPLY_PRODUCTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SUPPLY_PRODUCTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_SUPPLY_PRODUCTS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
